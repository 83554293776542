/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect, useState, useContext } from "react";
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { KTSVG } from '../../_metronic/helpers';
import CostCenterAssign from '../components/CostCenterAssign';
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactLoading from 'react-loading';
import userPermissionContext from '../../context/userPermissionContext';

function AddRegularExpenses() {
	const { jwt } = useContext(userPermissionContext);

	let history = useHistory();
	const customStylesSelect = {
		control: (provided) => ({
			...provided,
			height: 'calc(1.35em + 1.1rem + 2px)',
			minHeight: '35px'
		}),
		valueContainer: (provided) => ({
			...provided,
		})
	};

	const initialState = () => ({
		vehicleInfo: "",
		vehicleInfoId: "",
		driver: [],
		project: [],
		driverId: "",
		projectId: "",
		comments: "",
		billDate: "",
		employeeId: "",
		employeeName: "",
		idVmsVehicleType: "",
		totalExpenseAmount: 0
	})

	const tempExpenseDetailsInitialState = () => ({
		expenseHead: "",
		expenseType: "",
		expenseTypeId: "",
		ledgerName: "",
		ledgerId: "",
		costCenterName: "",
		costCenterId: "",
		expanded: [],
		expenseDate: "",
		expenseDateFrom: "",
		expenseDateTo: "",
		expenseAmount: "",
		hours: "",
		quantity: "",
		rate: "",
		currentMeterReading: "",
		previousMeterReading: "",
		distance: "",
		itemName: "",
		insuranceType: "",
		purpose: "",
		place: "",
		lastDateOfChanges: "",
		vehicleCondition: "",
		purchaseFrom: "",
		remarks: "",
		perLiterRun: 0,
		perKmFuelCost: 0,
		fileAttachment: null
	})
	const [tempExpenseDetails, setTempExpenseDetails] = useState(tempExpenseDetailsInitialState());
	const [mainExpenseDetails, setMainExpenseDetails] = useState([]);
	const [expenseHeads, setExpenseHeads] = useState([]);
	const [expenseInfo, setExpenseInfo] = useState(initialState());
	const [expenseTypes, setExpenseTypes] = useState([]);
	const [assignCostCenterModalShow, setAssignCostCenterModalShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const hasPermission = GetPagePermission('/expenses-regularExpensesAdd');
	var dataStatus = true;
	// const [costCenterShowHide, setCostCenterShowHide] = useState(true);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "getAllExpenseHeads", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setExpenseHeads(resp.data);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}, [])

	useEffect(() => {
		if (tempExpenseDetails.expenseHead !== "") {
			fetch(process.env.REACT_APP_API_URL + "getExpenseTypesByHead/" + tempExpenseDetails.expenseHead.value, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setExpenseTypes(resp.data);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
	}, [tempExpenseDetails.expenseHead])


	useEffect(() => {
		distanceChange();
	}, [tempExpenseDetails.currentMeterReading, tempExpenseDetails.previousMeterReading])

	useEffect(() => {
		if (tempExpenseDetails.expenseTypeId === 2 || tempExpenseDetails.expenseTypeId === 5 || tempExpenseDetails.expenseTypeId === 17 || tempExpenseDetails.expenseTypeId === 22 || tempExpenseDetails.expenseTypeId === 31 || tempExpenseDetails.expenseTypeId === 32 || tempExpenseDetails.expenseTypeId === 49) {
			rateOrQuantityChange();
		}

	}, [tempExpenseDetails.rate, tempExpenseDetails.quantity])

	useEffect(() => {
		setExpenseInfo({ ...expenseInfo, totalExpenseAmount: objPsum(mainExpenseDetails, 'expenseAmount') });
	}, [mainExpenseDetails])

	useEffect(() => {
		setTempExpenseDetails({ ...tempExpenseDetails, perLiterRun: (Number(tempExpenseDetails.distance) / Number(tempExpenseDetails.quantity)), perKmFuelCost: (Number(tempExpenseDetails.expenseAmount) / Number(tempExpenseDetails.distance)) })
	}, [tempExpenseDetails.distance, tempExpenseDetails.quantity, tempExpenseDetails.expenseAmount])

	const objPsum = (items, prop) => {
		return items.reduce(function (a, b) {
			return Number(a) + Number(b[prop]);
		}, 0);
	}


	const searchVehicles = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "vehicleSearchInHouse?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.vehicleNumber;
							element.value = element.vehicleInfoId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}


	const handleVehicleChange = vehicleDetails => {
		setExpenseInfo({
			...expenseInfo, vehicleInfoId: vehicleDetails.vehicleInfoId,
			projectId: vehicleDetails.currentProject,
			driverId: vehicleDetails.driverId,
			vehicleInfo: { label: vehicleDetails.vehicleNumber, value: vehicleDetails.vehicleInfoId },
			project: vehicleDetails.currentProjectName,
			driver: vehicleDetails.driverName,
			employeeId: vehicleDetails.currentUser,
			employeeName: vehicleDetails.currentUserName,
			employeeDesignation: vehicleDetails.currentUserDesignation,
			consultantOrAdvisorName: vehicleDetails.consultantOrAdvisorName,
			consultantOrAdvisorDesignation: vehicleDetails.consultantOrAdvisorDesignation,
			selfDriven: vehicleDetails.selfDriven,
			idVmsVehicleType: vehicleDetails.idVmsVehicleType
		});
		setMainExpenseDetails([]);
		// projectAccountingBudgetModuleCheck(vehicleDetails.currentProject);
	}

	const handleExpenseHeadChange = expenseHead => {
		setTempExpenseDetails({ ...tempExpenseDetails, expenseHead: { label: expenseHead.label, value: expenseHead.value }, expenseType: "", expenseTypeId: "", ledgerName: "", ledgerId: "" });
	}

	const handleExpenseTypeChange = expense => {
		setTempExpenseDetails({
			...tempExpenseDetails,
			expenseTypeId: expense.expenseTypeId,
			expenseType: { label: expense.label, value: expense.value },
			ledgerName: expense.ledgerName !== null ? expense.ledgerName : "",
			ledgerId: expense.ledgerId !== null ? expense.ledgerId : ""
		});
	}

	const handleExpenseCommonInfoChange = evt => {
		if (evt.target.name === "billDate") {
			const date = new Date().toISOString().slice(0, 10);
			if (date < evt.target.value) {
				toast.error("Bill date cannot be greater than current date");
			}
			else {
				setExpenseInfo({ ...expenseInfo, [evt.target.name]: evt.target.value });
			}
		}
		else {
			setExpenseInfo({ ...expenseInfo, [evt.target.name]: evt.target.value });
		}
	}

	const handleExpenseInfoChange = evt => {
		if (evt.target.name === "expenseDate") {
			const date = new Date().toISOString().slice(0, 10);
			if (date < evt.target.value) {
				toast.error("Expense Date cannot be greater than current date");
			}
			else {
				setTempExpenseDetails({ ...tempExpenseDetails, [evt.target.name]: evt.target.value });
			}
		}
		else {
			setTempExpenseDetails({ ...tempExpenseDetails, [evt.target.name]: evt.target.value });
		}

	}

	const distanceChange = () => {
		setTempExpenseDetails({ ...tempExpenseDetails, distance: (Number(tempExpenseDetails.currentMeterReading) - Number(tempExpenseDetails.previousMeterReading)) });
	}

	const rateOrQuantityChange = () => {
		setTempExpenseDetails({ ...tempExpenseDetails, expenseAmount: (Number(tempExpenseDetails.rate) * Number(tempExpenseDetails.quantity)) })
	}

	// const projectAccountingBudgetModuleCheck = (projectId) => {
	// 	if (projectId !== null) {
	// 		fetch(process.env.REACT_APP_API_URL + "projectBudgetModuleConnectionCheck/" + projectId, getRequestOptions())
	// 			.then((resp) => {
	// 				return resp.json()
	// 			})
	// 			.then((resp) => {
	// 				if (resp.data.budgetSetup === 0) {
	// 					setCostCenterShowHide(false);
	// 				}
	// 				else {
	// 					setCostCenterShowHide(true);
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.log(error, "catch the hoop")
	// 			});
	// 	}
	// }

	const addExpenseData = (expenseInfoData) => {

		if (expenseInfoData.expenseTypeId !== "") {
			if (expenseInfoData.expenseDate === "" || expenseInfoData.expenseAmount === "") {
				dataStatus = false;
			}
			// if (costCenterShowHide === true) {
			// 	if (expenseInfoData.costCenterId === "") {
			// 		dataStatus = false;
			// 	}
			// }
			if (expenseInfoData.expenseTypeId === 2 || expenseInfoData.expenseTypeId === 5 || expenseInfoData.expenseTypeId === 17 || expenseInfoData.expenseTypeId === 22 || expenseInfoData.expenseTypeId === 31 || expenseInfoData.expenseTypeId === 32 || expenseInfoData.expenseTypeId === 49) {
				if (expenseInfo.quantity === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 2 || expenseInfoData.expenseTypeId === 5 || expenseInfoData.expenseTypeId === 7 || expenseInfoData.expenseTypeId === 12 || expenseInfoData.expenseTypeId === 16 || expenseInfoData.expenseTypeId === 17 || expenseInfoData.expenseTypeId === 19 || expenseInfoData.expenseTypeId === 22 || expenseInfoData.expenseTypeId === 31 || expenseInfoData.expenseTypeId === 32 || expenseInfoData.expenseTypeId === 49) {
				if (expenseInfoData.rate === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 2 || expenseInfoData.expenseTypeId === 5 || expenseInfoData.expenseTypeId === 12 || expenseInfoData.expenseTypeId === 17 || expenseInfoData.expenseTypeId === 22 || expenseInfoData.expenseTypeId === 25 || expenseInfoData.expenseTypeId === 49) {
				if (expenseInfoData.currentMeterReading === "" || expenseInfoData.previousMeterReading === "" || expenseInfoData.distance === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 3) {
				if (expenseInfoData.itemName === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 11) {
				if (expenseInfoData.insuranceType === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 13 || expenseInfoData.expenseTypeId === 18 || expenseInfoData.expenseTypeId === 23 || expenseInfoData.expenseTypeId === 24 || expenseInfoData.expenseTypeId === 25 || expenseInfoData.expenseTypeId === 26 || expenseInfoData.expenseTypeId === 27) {
				if (expenseInfoData.purpose === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 21 || expenseInfoData.expenseTypeId === 30) {
				if (expenseInfoData.place === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 33) {
				if (expenseInfoData.vehicleCondition === "" || expenseInfoData.purchaseFrom === "") {
					dataStatus = false;
				}
			}
			if (expenseInfoData.expenseTypeId === 31 || expenseInfoData.expenseTypeId === 32) {
				if (expenseInfoData.remarks === "") {
					dataStatus = false;
				}
			}

			if (expenseInfoData.expenseTypeId === 19) {
				if (expenseInfoData.hours === "") {
					dataStatus = false;
				}
			}
		}
		else {
			dataStatus = false;
		}
		if (dataStatus === true) {
			setMainExpenseDetails(mainExpenseDetails => [...mainExpenseDetails, expenseInfoData]);
			setTempExpenseDetails(tempExpenseDetailsInitialState());
		}
		else {
			Swal.fire({
				title: 'Warning!',
				text: "Required Field Missing!",
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'Ok',
				cancelButtonText: 'No'
			});
		}
	}

	// const assignCostCenter = () => {
	// 	if (expenseInfo.projectId === "") {
	// 		setAssignCostCenterModalShow(false);
	// 		Swal.fire({
	// 			title: 'Warning!',
	// 			text: "Select Vehicle first!",
	// 			icon: 'warning',
	// 			showCancelButton: false,
	// 			confirmButtonText: 'Ok',
	// 			cancelButtonText: 'No'
	// 		});
	// 	}
	// 	else {
	// 		setAssignCostCenterModalShow(true);
	// 	}
	// }
	const assignCostCenterModalClose = () => {
		setAssignCostCenterModalShow(false);
	}
	const onAssignCostCenterConfirm = (costCenterId, costCenterName, expanded) => {
		setTempExpenseDetails({ ...tempExpenseDetails, costCenterId: costCenterId, costCenterName: costCenterName, expanded: expanded });
	}

	const onFileChange = event => {
		setTempExpenseDetails({ ...tempExpenseDetails, fileAttachment: { file: event.target.files[0], lastModified: event.target.files[0].lastModified, name: event.target.files[0].name, size: event.target.files[0].size, type: event.target.files[0].type } });
	}

	const removeExpenseDetails = (index) => () => {
		setMainExpenseDetails(mainExpenseDetails => mainExpenseDetails.filter((row, rindex) => index !== rindex));
	}

	const editExpenseDetails = (index) => () => {
		setTempExpenseDetails(mainExpenseDetails[index]);
		setMainExpenseDetails(mainExpenseDetails => mainExpenseDetails.filter((row, rindex) => index !== rindex));
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to submit this Regular Expenses!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				for (var i = 0; i < mainExpenseDetails.length; i++) {
					if (mainExpenseDetails[i].fileAttachment !== null) {
						formData.append('myFile[]', mainExpenseDetails[i].fileAttachment.file);
					}
				}
				formData.append("expenseInfo", JSON.stringify(expenseInfo));
				formData.append("expenseDetails", JSON.stringify(mainExpenseDetails));
				setIsLoading(true);
				axios.post(process.env.REACT_APP_API_URL + "regularExpensesAdd", formData, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt.token },
				})
					.then(response => {
						if (response.data.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: response.data.successMessage,
							})

							resetExpenseData();
							history.push("/expenses-regularExpensesDetails/" + response.data.data.id);
							setIsLoading(false);
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: response.data.errorMessage
							})
							setIsLoading(false);
						}
					})
					.catch((error) => {
						// setMainExpenseDetails(mainExpenseDetails => [...mainExpenseDetails.reverse()]);
						Swal.fire({
							icon: 'error',
							title: 'Error',
							html: error.response.data.errorMessage
						})
						setIsLoading(false);
					})
			}
		})
	}

	const resetExpenseData = () => {
		setExpenseInfo(initialState());
		setMainExpenseDetails([]);
		setTempExpenseDetails(tempExpenseDetailsInitialState());
	}

	const insuranceTypes = [
		{ label: 'Select Insurance Type', value: '' },
		{ label: '1st Party', value: '1st Party' },
		{ label: '3rd Party', value: '3rd Party' }
	]

	const vehicleConditions = [
		{ label: 'Select Vehicle Conditions', value: '' },
		{ label: 'Brand New', value: 'Brand New' },
		{ label: 'Recondition', value: 'Recondition' },
		{ label: 'Second Hand', value: 'Second Hand' }
	]

	return (
		<>
			{hasPermission === 1 &&
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<KTSVG
								path='/media/svg/icons/Shopping/Money.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
								EXPENSES INFORMATION
							</span>
						</div>
					</div>

					<div className="ml-12">
						<form onSubmit={handleSubmit}>
							<div className="card-body">
								<div className="ml-12">
									<div className="form-group row">
										<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-2 col-form-label" >Search Vehicle<span className="required text-danger"></span></label>
										<div className="col-lg-5">
											<AsyncSelect
												value={expenseInfo.vehicleInfo}
												defaultOptions
												loadOptions={searchVehicles}
												placeholder="Select Vehicle"
												onChange={handleVehicleChange}
												styles={customStylesSelect}
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="projectId" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }} > Current Project<span className="required text-danger"></span></label>
										<div className="col-lg-5">
											<input type="text" name="project" id="project" value={expenseInfo.project} readOnly placeholder="Project" className="form-control form-control-sm" />
										</div>
									</div>
									{expenseInfo.selfDriven !== "yes" &&
										<div className="form-group row">
											<label htmlFor="driverId" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }} >Current Driver{expenseInfo.idVmsVehicleType !== 14 && <span className="required text-danger"></span>}</label>
											<div className="col-lg-5">
												<input type="text" name="driver" id="driver" value={expenseInfo.driver || ""} readOnly placeholder="Driver" className="form-control form-control-sm" />
											</div>
										</div>
									}
									{expenseInfo.employeeName && expenseInfo.employeeName !== null &&
										<div className="form-group row">
											<label htmlFor="employeeName" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }} >Current User{expenseInfo.idVmsVehicleType === 14 && <span className="required text-danger"></span>}</label>
											<div className="col-lg-5">
												<input type="text" name="employeeName" id="employeeName" value={`${expenseInfo.employeeName} (${expenseInfo.employeeDesignation})` || ""} readOnly placeholder="User" className="form-control form-control-sm" />
											</div>
										</div>
									}
									{expenseInfo.consultantOrAdvisorName && expenseInfo.consultantOrAdvisorName !== null &&
										<div className="form-group row">
											<label htmlFor="consultantOrAdvisorName" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }} >Consultant / Advisor's</label>
											<div className="col-lg-5">
												<input type="text" name="consultantOrAdvisorName" id="consultantOrAdvisorName" value={`${expenseInfo.consultantOrAdvisorName} (${expenseInfo.consultantOrAdvisorDesignation})` || ""} readOnly placeholder="Consultant / Advisor's Name" className="form-control form-control-sm" />
											</div>
										</div>
									}
									<div className="form-group row">
										<label htmlFor="comments" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Comments <span className="required text-danger"></span></label>
										<div className="col-lg-5">
											<input type="text" name="comments" id="comments" value={expenseInfo.comments} onChange={handleExpenseCommonInfoChange} placeholder="Enter Comments" className="form-control form-control-sm" />
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="billDate" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }} >Bill Date<span className="required text-danger"></span></label>
										<div className="col-lg-5">
											<input type="date" name="billDate" id="billDate" value={expenseInfo.billDate} onChange={handleExpenseCommonInfoChange} placeholder="Select Bill Date" className="form-control form-control-sm" />
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="totalExpenseAmount" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Total Amount <span className="required text-danger"></span></label>
										<div className="col-lg-5">
											<input type="text" name="totalExpenseAmount" id="totalExpenseAmount" value={expenseInfo.totalExpenseAmount} readOnly placeholder="Total Amount" className="form-control form-control-sm" />
										</div>
									</div>
								</div>
								<div className="card-header">
									<div className="card-title">
										<KTSVG
											path='/media/svg/icons/Communication/Chat-check.svg'
											className='svg-icon-3 svg-icon-primary'
										/>
										<span className="text-primary" style={{ marginLeft: "5px" }}>
											Expenses Details
										</span>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group row mt-5">
											<label htmlFor="expenseHead" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Head<span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<Select
													value={tempExpenseDetails.expenseHead}
													options={expenseHeads}
													onChange={handleExpenseHeadChange}
													styles={customStylesSelect}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="expenseType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Type<span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<Select
													value={tempExpenseDetails.expenseType}
													options={expenseTypes}
													onChange={handleExpenseTypeChange}
													styles={customStylesSelect}
													isDisabled={tempExpenseDetails.expenseHead === "" ? true : false}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="ledgerName" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Ledger Name <span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<input type="text" name="ledgerName" id="ledgerName" value={tempExpenseDetails.ledgerName} readOnly placeholder="Ledger" className="form-control form-control-sm" />
											</div>
										</div>
										{/* {costCenterShowHide === true &&
											<div className="form-group row">
												<label htmlFor="costCenter" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Cost Center <span className="required text-danger"></span></label>
												<div className="col-lg-6">
													<input type="text" name="costCenter" id="costCenter" value={tempExpenseDetails.costCenterName} readOnly placeholder="Assign Cost Center" className="form-control form-control-sm" />
												</div>
												<div className="col-lg-2">
													<button type="button" className="btn btn-light btn-sm btn-active-light-primary" style={{ "marginLeft": "-30px" }} onClick={() => assignCostCenter()}>
														<KTSVG
															path='/media/svg/icons/Communication/Write.svg'
															className='svg-icon-5 svg-icon'
														/>
													</button>
												</div>
											</div>
										} */}
										<div className="form-group row">
											<label htmlFor="expenseDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Date<span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<input type="date" name="expenseDate" id="expenseDate" value={tempExpenseDetails.expenseDate} onChange={handleExpenseInfoChange} placeholder="Select Expense Date" className="form-control form-control-sm" />
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="expenseAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Expense Amount <span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<input type="number" name="expenseAmount" id="expenseAmount" value={tempExpenseDetails.expenseAmount} onChange={handleExpenseInfoChange} placeholder="Enter Expense Amount" className="form-control form-control-sm" />
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="fileAttachment" className="col-lg-4 col-form-label mt-2" style={{ "textAlign": "right" }}>Attach File </label>
											<div className="col-lg-8  col-form-label">
												<Form.Control type="file" size="sm" name="fileAttachment" id="fileAttachment" onChange={onFileChange} onClick={e => (e.target.value = null)} />
											</div>
										</div>
									</div>
									<div className="col-md-6 mt-5">
										{(tempExpenseDetails.expenseTypeId === 1 || tempExpenseDetails.expenseTypeId === 7 || tempExpenseDetails.expenseTypeId === 8 || tempExpenseDetails.expenseTypeId === 9
											|| tempExpenseDetails.expenseTypeId === 10 || tempExpenseDetails.expenseTypeId === 11 || tempExpenseDetails.expenseTypeId === 12 || tempExpenseDetails.expenseTypeId === 14
											|| tempExpenseDetails.expenseTypeId === 16 || tempExpenseDetails.expenseTypeId === 19 || tempExpenseDetails.expenseTypeId === 28 || tempExpenseDetails.expenseTypeId === 29) &&
											<>
												<div className="form-group row">
													<label htmlFor="expenseDateFrom" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Date From</label>
													<div className="col-lg-8">
														<input type="date" name="expenseDateFrom" id="expenseDateFrom" value={tempExpenseDetails.expenseDateFrom} onChange={handleExpenseInfoChange} placeholder="Select Date From" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="expenseDateTo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Date To</label>
													<div className="col-lg-8">
														<input type="date" name="expenseDateTo" id="expenseDateTo" value={tempExpenseDetails.expenseDateTo} onChange={handleExpenseInfoChange} placeholder="Select Date To" className="form-control form-control-sm" />
													</div>
												</div>
											</>
										}
										{tempExpenseDetails.expenseTypeId === 19 &&
											<div className="form-group row">
												<label htmlFor="hours" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Hours <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="number" name="hours" id="hours" value={tempExpenseDetails.hours} onChange={handleExpenseInfoChange} placeholder="Enter Hours" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempExpenseDetails.expenseTypeId === 2 || tempExpenseDetails.expenseTypeId === 5 || tempExpenseDetails.expenseTypeId === 17 || tempExpenseDetails.expenseTypeId === 22
											|| tempExpenseDetails.expenseTypeId === 31 || tempExpenseDetails.expenseTypeId === 32 || tempExpenseDetails.expenseTypeId === 49) &&
											<div className="form-group row">
												<label htmlFor="quantity" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Quantity <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="number" name="quantity" id="quantity" value={tempExpenseDetails.quantity} onChange={handleExpenseInfoChange} placeholder="Enter Quantity" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempExpenseDetails.expenseTypeId === 2 || tempExpenseDetails.expenseTypeId === 5 || tempExpenseDetails.expenseTypeId === 7 || tempExpenseDetails.expenseTypeId === 12
											|| tempExpenseDetails.expenseTypeId === 16 || tempExpenseDetails.expenseTypeId === 17 || tempExpenseDetails.expenseTypeId === 19 || tempExpenseDetails.expenseTypeId === 22
											|| tempExpenseDetails.expenseTypeId === 32 || tempExpenseDetails.expenseTypeId === 49 || tempExpenseDetails.expenseTypeId === 31) &&
											<div className="form-group row">
												<label htmlFor="rate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Rate <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="number" name="rate" id="rate" value={tempExpenseDetails.rate} onChange={handleExpenseInfoChange} placeholder="Enter Rate" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempExpenseDetails.expenseTypeId === 2 || tempExpenseDetails.expenseTypeId === 5 || tempExpenseDetails.expenseTypeId === 12 || tempExpenseDetails.expenseTypeId === 17
											|| tempExpenseDetails.expenseTypeId === 22 || tempExpenseDetails.expenseTypeId === 25 || tempExpenseDetails.expenseTypeId === 49) &&
											<>
												<div className="form-group row">
													<label htmlFor="currentMeterReading" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Current Meter Reading <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="number" name="currentMeterReading" id="currentMeterReading" value={tempExpenseDetails.currentMeterReading} onChange={handleExpenseInfoChange} placeholder="Enter Current Meter Reading" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="previousMeterReading" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Previous Meter Reading <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="number" name="previousMeterReading" id="previousMeterReading" value={tempExpenseDetails.previousMeterReading} onChange={handleExpenseInfoChange} placeholder="Enter Previous Meter Reading" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="distance" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Distance <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="number" name="distance" id="distance" value={tempExpenseDetails.distance} readOnly placeholder="Enter Distance" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="perLiterRun" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Per Liter Run (KM)</label>
													<div className="col-lg-8">
														<input type="number" name="perLiterRun" id="perLiterRun" value={tempExpenseDetails.perLiterRun} readOnly placeholder="Per Liter Run" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="perKmFuelCost" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Per KM Fuel Cost</label>
													<div className="col-lg-8">
														<input type="number" name="perKmFuelCost" id="perKmFuelCost" value={tempExpenseDetails.perKmFuelCost} readOnly placeholder="Per KM Fuel Cost" className="form-control form-control-sm" />
													</div>
												</div>
											</>
										}
										{tempExpenseDetails.expenseTypeId === 3 &&
											<div className="form-group row">
												<label htmlFor="itemName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Item Name <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="itemName" id="itemName" value={tempExpenseDetails.itemName} onChange={handleExpenseInfoChange} placeholder="Enter Item Name" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{tempExpenseDetails.expenseTypeId === 11 &&
											<div className="form-group row">
												<label htmlFor="insuranceType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Insurance Type<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<select className="form-select form-select-sm" id="insuranceType" name="insuranceType" value={tempExpenseDetails.insuranceType} onChange={handleExpenseInfoChange} >
														{insuranceTypes.map(function (item, id) {
															return <option key={id} value={item.value}>{item.label}</option>
														})
														}
													</select>
												</div>
											</div>
										}
										{(tempExpenseDetails.expenseTypeId === 13 || tempExpenseDetails.expenseTypeId === 18 || tempExpenseDetails.expenseTypeId === 23 || tempExpenseDetails.expenseTypeId === 24
											|| tempExpenseDetails.expenseTypeId === 25 || tempExpenseDetails.expenseTypeId === 26 || tempExpenseDetails.expenseTypeId === 27) &&
											<div className="form-group row">
												<label htmlFor="purpose" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purpose <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="purpose" id="purpose" value={tempExpenseDetails.purpose} onChange={handleExpenseInfoChange} placeholder="Enter Purpose" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempExpenseDetails.expenseTypeId === 21 || tempExpenseDetails.expenseTypeId === 30) &&
											<div className="form-group row">
												<label htmlFor="place" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Place <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="place" id="place" value={tempExpenseDetails.place} onChange={handleExpenseInfoChange} placeholder="Enter Place" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{tempExpenseDetails.expenseTypeId === 25 &&
											<div className="form-group row">
												<label htmlFor="lastDateOfChanges" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Last Date of Changes</label>
												<div className="col-lg-8">
													<input type="date" name="lastDateOfChanges" id="lastDateOfChanges" value={tempExpenseDetails.lastDateOfChanges} onChange={handleExpenseInfoChange} placeholder="Select Last Date of Changes" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{tempExpenseDetails.expenseTypeId === 33 &&
											<>
												<div className="form-group row">
													<label htmlFor="vehicleCondition" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Vehicle Condition<span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<select className="form-select form-select-sm" id="vehicleCondition" name="vehicleCondition" value={tempExpenseDetails.vehicleCondition} onChange={handleExpenseInfoChange} >
															{vehicleConditions.map(function (item, id) {
																return <option key={id} value={item.value}>{item.label}</option>
															})
															}
														</select>
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="purchaseFrom" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purchase From <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="text" name="purchaseFrom" id="purchaseFrom" value={tempExpenseDetails.purchaseFrom} onChange={handleExpenseInfoChange} placeholder="Enter Purchase From" className="form-control form-control-sm" />
													</div>
												</div>
											</>
										}
										{(tempExpenseDetails.expenseTypeId === 31 || tempExpenseDetails.expenseTypeId === 32) &&
											<div className="form-group row">
												<label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="remarks" id="remarks" value={tempExpenseDetails.remarks} onChange={handleExpenseInfoChange} placeholder="Enter Remarks" className="form-control form-control-sm" />
												</div>
											</div>
										}
									</div>
								</div>
								<div className="row">
									<div className="col-2">
									</div>
									<div className="col-6">
										<button type="button" className="btn btn-light-primary btn-sm" onClick={() => addExpenseData(tempExpenseDetails)}> <KTSVG
											path='/media/icons/duotune/files/fil005.svg'
											className='svg-icon-5 svg-icon'
										/>
											Add Expense</button>
									</div>
								</div>

								{mainExpenseDetails.length > 0 &&
									<>
										<div className="card-header">
											<div className="card-title">
												<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-3 svg-icon-primary' />
												<span className="text-primary" style={{ marginLeft: "5px" }}>
													Expenses List
												</span>
											</div>
										</div>
										<div className="tableFixHead">
											<div className="table-responsive" style={{ maxHeight: "280px", overflow: "auto", margin: "5px" }}>
												<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
													<thead>
														<tr style={{ "backgroundColor": "#C7E9EC" }}>
															<th>Action</th>
															<th>Sl</th>
															<th>Expense Type</th>
															<th>Ledger Name</th>
															<th>Cost Center</th>
															<th>Expense Date</th>
															<th>Expense Amount</th>
															<th>Date From</th>
															<th>Date To</th>
															<th>Hours</th>
															<th>Quantity</th>
															<th>Rate</th>
															<th>Current Meter Reading</th>
															<th>Previous Meter Reading</th>
															<th>Distance</th>
															<th>Per Liter Run (KM)</th>
															<th>Per KM Fuel Cost</th>
															<th>Item Name</th>
															<th>Insurance Type</th>
															<th>Purpose</th>
															<th>Place</th>
															<th>Last Date Of Changes</th>
															<th>Vehicle Condition</th>
															<th>Purchase From</th>
															<th>Remarks</th>
															<th>Attachment</th>
														</tr>
													</thead>
													<tbody>
														{
															mainExpenseDetails.map((expense, index) =>
																<tr key={index}>
																	<td>
																		<button type="button" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={editExpenseDetails(index)}>
																			<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon svg-icon-5' />
																		</button>
																		<button type="button" className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1" onClick={removeExpenseDetails(index)}>
																			<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
																		</button>
																	</td>
																	<td>{index + 1}</td>
																	<td> {expense.expenseType.label}</td>
																	<td> {expense.ledgerName}</td>
																	<td> {expense.costCenterName}</td>
																	<td> {expense.expenseDate}</td>
																	<td> {expense.expenseAmount}</td>
																	<td> {expense.expenseDateFrom}</td>
																	<td> {expense.expenseDateTo}</td>
																	<td> {expense.hours}</td>
																	<td> {expense.quantity}</td>
																	<td> {expense.rate}</td>
																	<td> {expense.currentMeterReading}</td>
																	<td> {expense.previousMeterReading}</td>
																	<td> {expense.distance}</td>
																	<td> {expense.perLiterRun}</td>
																	<td> {expense.perKmFuelCost}</td>
																	<td> {expense.itemName}</td>
																	<td> {expense.insuranceType}</td>
																	<td> {expense.purpose}</td>
																	<td> {expense.place}</td>
																	<td> {expense.lastDateOfChanges}</td>
																	<td> {expense.vehicleCondition}</td>
																	<td> {expense.purchaseFrom}</td>
																	<td> {expense.remarks}</td>
																	<td> {expense.fileAttachment &&
																		<>
																			{expense.fileAttachment.name}
																		</>
																	}</td>
																</tr>
															)
														}

													</tbody>
												</table>
											</div>
										</div>
									</>
								}
							</div>
							<div className="row">
								<div className="col-5">
								</div>
								<div className="col-6">
									<button type="submit" disabled={isLoading === true} className="btn btn-success btn-sm mb-5">Submit</button>
									<button type="reset" className="btn btn-danger btn-sm mb-5" onClick={() => resetExpenseData()}>Reset</button>
									{isLoading === true &&
										<ReactLoading type={"bars"} color={"#4739ea3d"} height={'20%'} width={'20%'} />
									}
								</div>
							</div>
							{/* <div className="row">
								<div className="col-4">
									<pre>{JSON.stringify(expenseInfo, null, 2)}</pre>
								</div>
								<div className="col-4">
									<pre>{JSON.stringify(tempExpenseDetails, null, 2)}</pre>
								</div>
								<div className="col-4">
									<pre>{JSON.stringify(mainExpenseDetails, null, 2)}</pre>
								</div>
							</div> */}
						</form>
					</div>
					<Modal size="lg" show={assignCostCenterModalShow} onHide={assignCostCenterModalClose} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Header style={{ paddingTop: "10px", paddingBottom: "10px" }}>
							<Modal.Title>
								<span>
									<KTSVG path='/media/svg/icons/Communication/Shield-user.svg' className='svg-icon-1 svg-icon-primary' />
								</span>
								<span className='text-primary fw-bolder' style={{ marginLeft: '5px' }}>
									ASSIGN COST CENTER
								</span>
								<span className='text-primary fw-bolder' style={{ marginLeft: '485px' }}>
									<button type="button" className='btn btn-sm btn-icon btn-active-color-primary' onClick={assignCostCenterModalClose}>
										<KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1 svg-icon' />
									</button>
								</span>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ paddingTop: "0.75rem" }}>
							<CostCenterAssign onAssignCostCenterConfirm={onAssignCostCenterConfirm} idCostCenter={tempExpenseDetails.costCenterId} costCenterName={tempExpenseDetails.costCenterName} expanded={tempExpenseDetails.expanded} projectId={expenseInfo.projectId} />
						</Modal.Body>
						<Modal.Footer style={{ marginTop: "-24px" }}>
							<button className='btn btn-success btn-sm' onClick={assignCostCenterModalClose}>Ok</button>
							<button className='btn btn-danger btn-sm' onClick={assignCostCenterModalClose}>Cancel</button>
						</Modal.Footer>
					</Modal>
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)

}

export default AddRegularExpenses