import React, { useState, useEffect, useImperativeHandle } from "react";
import Radio from "@mui/material/Radio";

const fuelTypes = [
    { label: 'Octane', value: 'octane' },
    { label: 'Diesel', value: 'diesel' },
    { label: 'Petrol', value: 'petrol' },
    { label: 'CNG', value: 'cng' },
    { label: 'LPG', value: 'lpg' },
    { label: 'CNG-Octane', value: 'cng_octane' },
    { label: 'LPG-Octane', value: 'lpg_octane' },
];

const VehicleDetails = React.forwardRef(({ vehicleDetailsEditData, onVehicleDetailsChange }, ref) => {
    const initialState = {
        noOfTyres: "",
        tyreSize: "",
        seatCapacity: "",
        spareTyres: "no",
        fuelCapacity: "",
        cylinderCapacity: "",
        engineNumber: "",
        ladenWeight: "",
        engineCcDisplacement: "",
        runBy: "",
        parkingLocation: "",
    };

    const [vehicleDetails, setVehicleDetails] = useState(initialState);

    useImperativeHandle(ref, () => ({
        setInitialState: () => setVehicleDetails(initialState),
    }));

    useEffect(() => {
        if (vehicleDetailsEditData) setVehicleDetails(vehicleDetailsEditData);
    }, [vehicleDetailsEditData]);

    useEffect(() => {
        onVehicleDetailsChange(vehicleDetails);
    }, [vehicleDetails, onVehicleDetailsChange]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVehicleDetails((prev) => ({ ...prev, [name]: value }));
    };

    const tyres = Array.from({ length: 41 }, (_, i) => i + 2);
    const seatCapacity = Array.from({ length: 40 }, (_, i) => i + 1);

    return (
        <>
            {[
                { label: "No of Tyres", name: "noOfTyres", type: "select", options: tyres, required: true },
                { label: "Tyre Size", name: "tyreSize", type: "text", required: true },
                { label: "Seat Capacity", name: "seatCapacity", type: "select", options: seatCapacity, required: true },
                { label: "Cylinder Capacity", name: "cylinderCapacity", type: "number", required: true },
                { label: "Engine Number", name: "engineNumber", type: "text", required: true },
                { label: "Laden Weight (kg)", name: "ladenWeight", type: "number", required: true },
                { label: "Engine (CC) Displacement", name: "engineCcDisplacement", type: "number", required: true },
                { label: "Run By", name: "runBy", type: "select", options: fuelTypes, required: true },
                { label: "Fuel Capacity", name: "fuelCapacity", type: "number", required: true },
                { label: "Parking Location", name: "parkingLocation", type: "text" },
            ].map(({ label, name, type, options, required }) => (
                <div className="form-group row" key={name}>
                    <label htmlFor={name} className="col-lg-3 col-form-label text-end">
                        {label} {required && <span className="required text-danger"></span>}
                    </label>
                    <div className="col-lg-5">
                        {type === "select" ? (
                            <select
                                className="form-select form-select-sm"
                                id={name}
                                name={name}
                                value={vehicleDetails[name]}
                                onChange={handleChange}
                            >
                                <option value="">Select {label}</option>
                                {options.map((item) =>
                                    typeof item === "object" ? (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ) : (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    )
                                )}
                            </select>
                        ) : (
                            <input
                                type={type}
                                name={name}
                                id={name}
                                value={vehicleDetails[name]}
                                onChange={handleChange}
                                className="form-control form-control-sm"
                            />
                        )}
                    </div>
                </div>
            ))}

            <div className="form-group row">
                <label className="col-lg-3 col-form-label text-end">Spare Tyres</label>
                <div className="col-lg-5">
                    {["yes", "no"].map((option) => (
                        <label key={option} className="me-3">
                            <Radio
                                checked={vehicleDetails.spareTyres === option}
                                onClick={() => setVehicleDetails((prev) => ({ ...prev, spareTyres: option }))}
                                value={option}
                                name="spareTyres"
                            />
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
});

export default React.memo(VehicleDetails);