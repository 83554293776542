import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import AddVehicle from '../pages/AddVehicle';
import EditVehicle from '../pages/EditVehicle';
import AddDriver from '../pages/AddDriver';
import DriverList from '../pages/DriverList';
import Expense from '../pages/Expense';
import AddRegularExpenses from '../pages/AddRegularExpenses';
import RegularExpensesList from '../pages/RegularExpensesList';
import VehicleList from '../pages/VehicleList';
import VehicleMotorBikeList from '../pages/VehicleMotorBikeList';
import TransferVehicle from '../pages/TransferVehicle';
import VehicleDetails from '../pages/DetailsVehicle';
import RegularExpensesDetails from '../pages/RegularExpensesDetails';
import InvoiceCreate from '../pages/InvoiceCreate';
import InvoiceList from '../pages/InvoiceList';
import InvoiceBillCreate from '../pages/InvoiceBillCreate';
import PageList from '../pages/PageList';
import PageManagement from '../pages/PageManagement';
import RoleManagement from '../pages/RoleManagement';
import UserRolePermission from '../pages/UserRolePermission';
import InvoiceBillList from '../pages/InvoiceBillList';
import InvoiceBillDetails from '../pages/InvoiceBillDetails';
import AdvanceBillCreate from '../pages/AdvanceBillCreate';
import AdvanceBillList from '../pages/AdvanceBillList';
import RentalBillCreate from '../pages/RentalBillCreate';
import VmoCreate from '../pages/VmoCreate';
import VmoList from '../pages/VmoList';
import VmoApprove from '../pages/VmoApprove';
import VmoDetails from '../pages/VmoDetails';
import VehicleRateSetup from '../pages/VehicleRateSetup';
import VehicleRelease from '../pages/VehicleRelease';
import VehicleReleaseList from '../pages/VehicleReleaseList';
import VehicleReleaseDetails from '../pages/VehicleReleaseDetails';
import VehicleExpenseReport from '../pages/reports/VehicleExpenseReport';
import VmoEdit from '../pages/VmoEdit';
import RentalBillList from '../pages/RentalBillList';
import RentalBillDetails from '../pages/RentalBillDetails';
import ToDoList from '../pages/ToDoList';
import ExpenseHead from '../pages/ExpenseHead';
import VehicleInfoReport from '../pages/reports/VehicleInfoReport';
import VehicleCostReport from '../pages/reports/VehicleCostReport';
import EditDriver from '../pages/EditDriver';

export function PrivateRoutes() {


	return (
		<Suspense fallback={<FallbackView />}>
			<Switch>
				<Route path='/dashboard' component={DashboardWrapper} />
				<Route path='/to-do-list' component={ToDoList} />
				<Route path='/vehicleAdd' component={AddVehicle} />
				<Route path='/vehicleEdit/:id' component={EditVehicle} />
				<Route path='/vehicleList' component={VehicleList} />
				<Route path='/vehicleMotorBikeList' component={VehicleMotorBikeList} />
				<Route path='/vehicleDetails/:id' component={VehicleDetails} />
				<Route path='/vehicleTransfer' component={TransferVehicle} />
				<Route path='/driverAdd' component={AddDriver} />
				<Route path='/driverList' component={DriverList} />
				<Route path='/setup-expense-types' component={Expense} />
				<Route path='/expenses-regularExpensesAdd' component={AddRegularExpenses} />
				<Route path='/expenses-regularExpensesList' component={RegularExpensesList} />
				<Route path='/expenses-regularExpensesDetails/:id' component={RegularExpensesDetails} />
				<Route path='/expenses-invoiceCreate' component={InvoiceCreate} />
				<Route path='/expenses-invoiceList' component={InvoiceList} />
				<Route path='/expenses-invoiceBillCreate' component={InvoiceBillCreate} />
				<Route path='/accessControl-pageList' component={PageList} />
				<Route path='/accessControl-pageManagement' component={PageManagement} />
				<Route path='/accessControl-roleManagement' component={RoleManagement} />
				<Route path='/accessControl-userRolePermission' component={UserRolePermission} />
				<Route path='/expenses-invoiceBillList' component={InvoiceBillList} />
				<Route path='/expenses-invoiceBillDetails/:id' component={InvoiceBillDetails} />
				<Route path='/expenses-advanceBillCreate' component={AdvanceBillCreate} />
				<Route path='/expenses-advanceBillList' component={AdvanceBillList} />
				<Route path='/rental-billCreate' component={RentalBillCreate} />
				<Route path='/vmo-create' component={VmoCreate} />
				<Route path='/vmo-list' component={VmoList} />
				<Route path='/vmo-edit/:id' component={VmoEdit} />
				<Route path='/vmo-approve/:id' component={VmoApprove} />
				<Route path='/vmo-details/:id' component={VmoDetails} />
				<Route path='/setup-vehicle-rate' component={VehicleRateSetup} />
				<Route path='/vro-create' component={VehicleRelease} />
				<Route path='/vro-list' component={VehicleReleaseList} />
				<Route path='/vro-vehicleReleaseDetails/:id' component={VehicleReleaseDetails} />
				<Route path='/report-vehicleExpenseReport' component={VehicleExpenseReport} />
				<Route path='/report-vehicleInfoReport' component={VehicleInfoReport} />
				<Route path='/rental-billList' component={RentalBillList} />
				<Route path='/rental-billDetails/:id' component={RentalBillDetails} />
				<Route path='/setup-expense-head' component={ExpenseHead} />
				<Route path='/report-vehicleCostReport' component={VehicleCostReport} />
				<Route path='/driverEdit/:id' component={EditDriver} />
				<Redirect from='/auth' to='/dashboard' />
				<Redirect exact from='/' to='/dashboard' />
				<Redirect to='error/404' />
			</Switch>
		</Suspense>
	)
}
