import { useEffect, useContext } from 'react';
import BackendTable from 'react-backend-table';
import { Link } from "react-router-dom";
import { KTSVG } from '../../_metronic/helpers';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import DriverAssign from '../components/DriverAssign';
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from '../components/Error500';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getRequestOptions } from "../components/GetToken";
import userPermissionContext from '../../context/userPermissionContext';

function VehicleList() {
	const { jwt } = useContext(userPermissionContext);
	const [idVmsVehicleInfo, setIdVmsVehicleInfo] = useState('');
	const [assignDriverModalShow, setAssignDriverModalShow] = useState(false);
	const [reload, setReload] = useState(false);
	const [projectList, setProjectList] = useState([]);
	const [vehicleTypeList, setVehicleTypeList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const hasPermission = GetPagePermission("/vehicleList");
	const [editPermission, setEditPermission] = useState("");
	const imagePath = process.env.REACT_APP_AWS_URL + 'vms/uploads/';
	const [formData, setFormData] = useState({
		vehicleStatus: "Active",
		listType: "Light",
	});


	const getProjectList = () => {
		fetch(process.env.REACT_APP_API_URL + "getProjectList", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setProjectList(resp.data.map((item) => ({ label: item.label, value: item.label })));
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const getVehicleType = () => {
		fetch(process.env.REACT_APP_API_URL + "vehicleType", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setVehicleTypeList(resp.data.map((item) => ({ label: item.label, value: item.label })));
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const getAllCategories = () => {
		fetch(process.env.REACT_APP_API_URL + "categories", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setCategoryList(resp.data.map((item) => ({ label: item.label, value: item.label })));
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const getAllBrand = () => {
		fetch(process.env.REACT_APP_API_URL + "vehicleBrandList", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setBrandList(resp.data.map((item) => ({ label: item.label, value: item.label })));
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	useEffect(() => {
		getProjectList();
		getVehicleType();
		getAllCategories();
		getAllBrand();
	}, [])

	let columns = [
		{ title: 'Sid', field: 'idVmsVehicleInfo', sortable: true, searchable: true },
		{
			title: 'Thumbnail',
			field: 'thumbnailName',
			sortable: true,
			searchable: false,
			thStyle: { textAlign: "center" },
			tdStyle: { textAlign: "center" },
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					rowValue.thumbnailName !== null ?
						<img src={`${imagePath}${rowValue.idVmsVehicleInfo}/images/thumb/${rowValue.thumbnailName}`} style={{ width: "80px", height: "80px", objectFit: "cover" }} alt="" />
						: ""
				)

			})
		},
		{
			title: 'Number', field: 'vehicleNumber', sortable: true, searchable: true,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
						{rowValue.vehicleNumber}
					</Link>
				)

			})
		},
		{ title: 'Owner', field: 'vehicleOwner', sortable: true, searchable: true, isSelect: true, selectOptions: [{ label: 'In House', value: 'In House' }, { label: 'Third Party', value: 'Third Party' }], searchBarWidth: '120px' },
		{ title: 'Vendor', field: 'vendorName', searchable: true, sortable: true },
		{ title: 'Type', field: 'vehicleType', sortable: true, searchable: true, isMultiSelect: true, selectOptions: vehicleTypeList, searchBarWidth: '150px' },
		{ title: 'Category', field: 'categoryName', searchable: true, sortable: true, isMultiSelect: true, selectOptions: categoryList, searchBarWidth: '150px' },
		{ title: 'Brand', field: 'productName', searchable: true, sortable: true, isMultiSelect: true, selectOptions: brandList, searchBarWidth: '150px' },
		{ title: 'Model', field: 'modelName', searchable: true, sortable: true },
		{ title: 'Mfg Year', field: 'yearOfManufacture', sortable: true, searchable: true },
		{ title: 'Color', field: 'colorName', searchable: true, sortable: true },
		{ title: 'PO Date', field: 'purchaseDate', sortable: true, searchable: true },
		{ title: 'Payable ?', field: 'usageType', sortable: true, searchable: true, isSelect: true, selectOptions: [{ label: 'BOQ', value: 'BOQ' }, { label: 'Overhead', value: 'Overhead' }], searchBarWidth: '120px' },
		{ title: 'Asset Code', field: 'fixedAssetCode', searchable: true, sortable: true },
		{ title: 'Rental', field: 'rentalType', sortable: true, searchable: true, isSelect: true, selectOptions: [{ label: 'Rental Car (Full)', value: 'rental-car-full' }, { label: 'Rental Car (Body)', value: 'rental-car-body' }, { label: 'Not Available', value: 'not-available' }], searchBarWidth: '120px' },
		{ title: 'Use Type', field: 'carUseType', sortable: true, searchable: true, isSelect: true, selectOptions: [{ label: 'Pool', value: 'pool' }, { label: 'Individual', value: 'individual' }, { label: 'Sharing', value: 'sharing' }], searchBarWidth: '130px' },
		{ title: 'Run By', field: 'runBy', sortable: true, searchable: true, isSelect: true, selectOptions: [{ label: 'Octane', value: 'octane' }, { label: 'Diesel', value: 'diesel' }, { label: 'Petrol', value: 'petrol' }, { label: 'CNG', value: 'cng' }, { label: 'LPG', value: 'lpg' }, { label: 'CNG-Octane', value: 'cng_octane' }, { label: 'LPG-Octane', value: 'lpg_octane' }], searchBarWidth: '120px' },
		{ title: 'Project', field: 'assignedProject', sortable: true, searchable: true, isMultiSelect: true, selectOptions: projectList, searchBarWidth: '150px' },
		{ title: 'Personnel', field: 'assignedUser', sortable: true, searchable: true },
		{ title: 'Phone', field: 'assignedUserPhone', sortable: true, searchable: true },
		{ title: 'Designation', field: 'assignedUserDesignation', sortable: true, searchable: true },
		{ title: 'Consultant Or Advisor', field: 'consultantOrAdvisorName', searchable: true, sortable: true },
		{ title: 'Designation', field: 'consultantOrAdvisorDesignation', searchable: true, sortable: true },
		{ title: 'Self Driven', field: 'selfDriven', sortable: true, searchable: true, isSelect: true, selectOptions: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }], searchBarWidth: '120px' },
		{ title: 'Driver', field: 'assignedDriver', sortable: true, searchable: true },
		{ title: 'Driver Mobile', field: 'driverMobileNumber', sortable: true, searchable: true },
		{
			title: 'F Status',
			field: 'functionalStatus',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: (rowValue) => {
				return rowValue.functionalStatus === "yes" ? (
					<span className="badge badge-light-success fw-bolder">Yes</span>
				) : rowValue.functionalStatus === "no" ? (
					<span className="badge badge-light-danger fw-bolder">No</span>
				) : null;
			},
		},
		{
			title: 'Status',
			field: 'vehicleStatus',
			sortable: false,
			searchable: false,
			tdClass: "text-center",
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (rowValue.vehicleStatus === "Active" ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>)
			})
		},
		{ title: 'Create Date', field: 'createDate', sortable: true, searchable: true },
		{
			title: "Action",
			field: "action",
			thStyle: { textAlign: "center" },
			tdStyle: { whiteSpace: 'nowrap' },
			sortable: false,
			searchable: false,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<>
						<Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`}>
							<button className="btn btn-light-primary btn-sm">
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-5 svg-icon'
								/>		Details
							</button>
						</Link>
						<br></br>
						{editPermission !== "" &&
							<Link to={`/vehicleEdit/${rowValue.idVmsVehicleInfo}`}>
								<button className="btn btn-light-danger btn-sm">
									<KTSVG
										path='/media/svg/icons/Text/Bullet-list.svg'
										className='svg-icon-5 svg-icon'
									/>		Edit
								</button>
							</Link>
						}
						<br></br>
						{rowValue.vehicleType !== "Motor Cycle" && rowValue.selfDriven === "no" &&
							<button className="btn btn-light-info btn-sm mt-1" onClick={() => assignDriver(rowValue.idVmsVehicleInfo)}>
								<KTSVG
									path='/media/svg/icons/Communication/Add-user.svg'
									className='svg-icon-5 svg-icon'
								/>Assign Driver
							</button>
						}
					</>
				)
			})
		}
	]

	let options = {
		url: process.env.REACT_APP_API_URL + 'vehicleList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [50, 100, 500, 1000, 'All'],
		orderBy: 'thumbnailName',
		orderType: 'desc',
		columnSearch: true,
		extraData: formData
	}

	const assignDriver = (id) => {
		setAssignDriverModalShow(true);
		setIdVmsVehicleInfo(id);
	}
	const assignDriverModalClose = () => {
		setAssignDriverModalShow(false);
	};

	const onAssignDriverConfirm = () => {
		setAssignDriverModalShow(false);
		setReload(true);
	}

	useEffect(() => {
		if (reload) {
			options.reloadMyTable();
			setReload(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload])

	useEffect(() => {
		const vehicleEditPermission = () => {
			fetch(process.env.REACT_APP_API_URL + "vehicleEditPermission", getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setEditPermission(resp.data.idPages);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});

		}
		vehicleEditPermission();
	}, [])

	const handleInputOnChange = evt => {
		setFormData({ ...formData, [evt.target.name]: evt.target.value });
		setReload(true);
	}

	const vehicleStatus = [
		{ label: 'All', value: '' },
		{ label: 'Active', value: 'Active' },
		{ label: 'Inactive', value: 'Inactive' }
	]

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header d-flex flex-direction-row justify-content-between py-4'>
						<div className='card-title w-50'>
							<span>
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								VEHICLES LIST
							</span>
						</div>
						<div className='w-50 d-flex justify-content-end align-items-center'>
							<label htmlFor="vehicleStatus" className="col-form-label mx-2">Vehicle Status: </label>
							<div className="">
								<select className="form-select form-select-sm" id="vehicleStatus" name="vehicleStatus" value={formData.vehicleStatus} onChange={handleInputOnChange} >
									{vehicleStatus.map(function (item, id) {
										return <option key={id} value={item.value}>{item.label}</option>
									})
									}
								</select>
							</div>
						</div>
					</div>

					<div className='ml-12'>
						<div className='' style={{ overflowX: "hidden" }}>
							<BackendTable columns={columns} options={options} />
						</div>
					</div>
					<Modal size="lg" show={assignDriverModalShow} onHide={assignDriverModalClose} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Header style={{ paddingTop: "10px", paddingBottom: "10px" }}>
							<Modal.Title>
								<span>
									<KTSVG
										path='/media/svg/icons/Communication/Shield-user.svg'
										className='svg-icon-1 svg-icon-primary'
									/>
								</span>
								<span className='text-primary fw-bolder' style={{ marginLeft: '5px' }}>
									ASSIGN DRIVER
								</span>

							</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ paddingTop: "0.75rem" }}>
							<DriverAssign onAssignDriverConfirm={onAssignDriverConfirm} idVmsVehicleInfo={idVmsVehicleInfo} />
						</Modal.Body>

						<Modal.Footer>
							<button className='btn btn-light btn-sm' onClick={assignDriverModalClose}>Cancel</button>
						</Modal.Footer>
					</Modal>
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default VehicleList