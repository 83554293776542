import { useState, useContext } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { KTSVG } from '../../_metronic/helpers';
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
import userPermissionContext from '../../context/userPermissionContext';

function AddDriver() {
    const { jwt } = useContext(userPermissionContext);
    const initialState = () => ({
        driverType: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mobile: "",
        phone: "",
        nid: "",
        licenceType: "light",
        licenceNo: "",
        licenceValidity: "",
        jobTitle: "",
        employeeId: "",
        company: "",
        project: "",
        reference: "",
        status: "active",
        employeeInfo: []
    })

    const [isDisabled, setIsDisabled] = useState(false);
    const [driverInfo, setDriverInfo] = useState(initialState());
    const hasPermission = GetPagePermission("/driverAdd");
    const getEmployees = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        }
        else {
            const url = process.env.REACT_APP_API_URL + "driverGet?q=" + inputValue;
            setTimeout(() => {
                fetch(url, getRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.full_name;
                            element.value = element.employee_id;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }


    const handleInputDriverInfoChange = evt => {
        setDriverInfo({ ...driverInfo, [evt.target.name]: evt.target.value });
    }

    const handleEmployeeChange = employeeDetails => {
        setDriverInfo({
            ...driverInfo,
            employeeInfo: employeeDetails,
            firstName: employeeDetails.first_name,
            middleName: employeeDetails.middle_name,
            lastName: employeeDetails.last_name,
            mobile: employeeDetails.phone_number,
            phone: employeeDetails.off_number,
            nid: employeeDetails.nid,
            jobTitle: employeeDetails.designation_title,
            employeeId: employeeDetails.employee_custom_id,
            company: employeeDetails.company_name,
            project: employeeDetails.project_name
        });
    }

    const handleDriverTypeChange = evt => {
        if (evt.target.value !== 'MAX Own') {
            setDriverInfo({
                ...driverInfo,
                driverType: evt.target.value
            });
            setIsDisabled(false);
        } else {
            setDriverInfo({ ...driverInfo, driverType: evt.target.value });
            setIsDisabled(true);
        }
    }

    let history = useHistory();
    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to add this driver!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {
                    driverInfo: driverInfo
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                var apiEnd = "driverAdd";
                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: resp.successMessage,
                            })
                            setDriverInfo(initialState());
                            history.push("/driverList");
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }

    return (
        <>
            {hasPermission === 1 &&

                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Communication/Adress-book2.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                ADD DRIVER INFORMATION
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">

                                <div className="form-group row">
                                    <label htmlFor="driverType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Driver Type <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <select className="form-select form-select-sm" id="driverType" name="driverType" onChange={handleDriverTypeChange} value={driverInfo.driverType}>
                                            <option value="">Select Driver Type</option>
                                            <option value="MAX Own">Max Own</option>
                                            <option value="Third Party">Third Party</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    driverInfo.driverType === "MAX Own" && <>

                                        <div className="form-group row">
                                            <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label">Select Employee</label>
                                            <div className="col-lg-5">
                                                <AsyncSelect
                                                    value={driverInfo.employeeInfo}
                                                    defaultOptions
                                                    loadOptions={getEmployees}
                                                    placeholder="Select Employee"
                                                    onChange={handleEmployeeChange}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }


                                <div className="form-group row">
                                    <label htmlFor="firstName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        First Name <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="firstName" id="firstName" value={driverInfo.firstName} className="form-control form-control-sm" disabled={isDisabled} onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="middleName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Middle Name
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="middleName" id="middleName" value={driverInfo.middleName} className="form-control form-control-sm" disabled={isDisabled} onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="lastName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Last Name
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="lastName" id="lastName" value={driverInfo.lastName} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mobile" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Mobile <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="number" name="mobile" id="mobile" value={driverInfo.mobile} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="phone" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Phone
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="number" name="phone" id="phone" value={driverInfo.phone} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="licenceType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Licence Type <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <select className="form-select form-select-sm" id="licenceType" name="licenceType" onChange={handleInputDriverInfoChange} value={driverInfo.licenceType}>
                                            <option value="light">Light</option>
                                            <option value="medium">Medium</option>
                                            <option value="heavy">Heavy</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="licenceNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Licence No <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="licenceNo" id="licenceNo" value={driverInfo.licenceNo} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="licenceValiditiy" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Licence Validity <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="date" name="licenceValidity" id="licenceValidity" value={driverInfo.licenceValidity} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="nid" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        National Id
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="nid" id="nid" value={driverInfo.nid} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="jobTitle" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Job Title <span className="required text-danger"></span>
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="jobTitle" id="jobTitle" value={driverInfo.jobTitle} className="form-control form-control-sm" disabled={isDisabled} onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="employeeId" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Employee Id
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="employeeId" id="employeeId" value={driverInfo.employeeId} className="form-control form-control-sm" disabled={isDisabled} onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="company" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Company
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="company" id="company" value={driverInfo.company} className="form-control form-control-sm" disabled={isDisabled} onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Project
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="project" id="project" value={driverInfo.project} className="form-control form-control-sm" disabled={isDisabled} onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="reference" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Reference
                                    </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="reference" id="reference" value={driverInfo.reference} className="form-control form-control-sm" onChange={handleInputDriverInfoChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="status" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>
                                        Status
                                    </label>
                                    <div className="col-lg-5">
                                        <select className="form-select form-select-sm" id="status" name="status" onChange={handleInputDriverInfoChange} value={driverInfo.status}>
                                            <option value="active">Active</option>
                                            <option value="resigned">Resigned</option>
                                            <option value="service_closed">Service Closed</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-5">
                                    </div>
                                    <div className="col-6">
                                        <button type="submit" className="btn btn-sm btn-success me-2">Submit</button>
                                        <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            {/* <pre>{ JSON.stringify(driverInfo, null, 2) }</pre> */}
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>

    )
}

export default AddDriver
