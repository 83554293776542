import React, { useState, useEffect, useImperativeHandle, useCallback, useMemo } from "react";

const VehicleInsurance = React.forwardRef(({ vehicleInsuranceEditData, onVehicleInsuranceChange }, ref) => {

    const initialState = useMemo(() => ({
        insuranceType: "",
        insuranceCompany: "",
        insurancePolicyNumber: "",
        insuranceExpirationDate: "",
        yearlyPremium: "",
        source: "",
    }), []);

    const [vehicleInsurance, setVehicleInsurance] = useState(initialState);

    useImperativeHandle(ref, () => ({
        setInitialState: () => setVehicleInsurance(initialState),
    }), [initialState]);

    useEffect(() => {
        if (vehicleInsuranceEditData) {
            setVehicleInsurance(vehicleInsuranceEditData);
        }
    }, [vehicleInsuranceEditData]);

    useEffect(() => {
        onVehicleInsuranceChange(vehicleInsurance);
    }, [vehicleInsurance, onVehicleInsuranceChange]);

    const handleInputVehicleInsuranceChange = useCallback((evt) => {
        setVehicleInsurance((prev) => ({
            ...prev,
            [evt.target.name]: evt.target.value,
        }));
    }, []);

    const insuranceTypes = [
        { label: "1st Party", value: "1st Party" },
        { label: "3rd Party", value: "3rd Party" },
    ];

    return (
        <div>
            {/* Insurance Type */}
            <div className="form-group row">
                <label htmlFor="insuranceType" className="col-lg-3 col-form-label text-end">Insurance Type</label>
                <div className="col-lg-5">
                    <select
                        id="insuranceType"
                        name="insuranceType"
                        value={vehicleInsurance.insuranceType}
                        onChange={handleInputVehicleInsuranceChange}
                        className="form-select form-select-sm"
                    >
                        <option value="">Select Insurance Type</option>
                        {insuranceTypes.map(({ label, value }) => (
                            <option key={value} value={value}>{label}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Other Input Fields */}
            {[
                { name: "insuranceCompany", label: "Insurance Company" },
                { name: "insurancePolicyNumber", label: "Insurance Policy Number" },
                { name: "insuranceExpirationDate", label: "Insurance Expiration Date", type: "date" },
                { name: "yearlyPremium", label: "Yearly Premium", type: "number" },
                { name: "source", label: "Source" }
            ].map(({ name, label, type = "text" }) => (
                <div className="form-group row" key={name}>
                    <label htmlFor={name} className="col-lg-3 col-form-label text-end">{label}</label>
                    <div className="col-lg-5">
                        <input
                            type={type}
                            name={name}
                            id={name}
                            value={vehicleInsurance[name]}
                            onChange={handleInputVehicleInsuranceChange}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
});

export default React.memo(VehicleInsurance);
