/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { getRequestOptions } from "../components/GetToken";
import { KTSVG } from '../../_metronic/helpers';
import InputMask from 'react-input-mask';

const VehicleInfo = React.forwardRef((props, ref) => {
    const customStylesSelect = {
        control: (provided) => ({
            ...provided,
            height: 'calc(1.35em + 1.1rem + 2px)',
            minHeight: '35px'
        }),
        valueContainer: (provided) => ({
            ...provided,
        })
    };

    const initialState = () => ({
        vehicleOwner: "In-House",
        usageType: "BOQ",
        vehicleType: '',
        rentalType: 'rental-car-full',
        vendor: [],
        idCategories: "",
        idColor: "",
        idProducts: "",
        idModels: "",
        capDetails: [],
        categories: [],
        color: [],
        brand: [],
        model: [],
        fixedAmount: "",
        lastPolicyReviewOn: "",
        milage: "",
        monthlyExpense: "",
        lastReviewDate: "",
        vehicleCity: "",
        vehicleClass: "",
        vehicleRegistrationNumber: "",
        vehicleNumber: "",
        manufacturer: "",
        yearOfManufacture: "",
        chasisNumber: "",
        vtsNo: "",
        eTrackingNumber: "",
        overHang: "",
        registrationDate: "",
        transactionNumber: "",
        nameOfRegistration: "",
        currentMeterReading: "",
        taxAmount: "",
        taxTokenExpirationDate: "",
        fitnessExpirationDate: "",
        routePermitExpirationDate: "",
        vehicleStatus: "Active",
        functional: "",
        purchaseDate: "",
        vtsOrGps: "no",
        vtsOrGpsFunctioning: "yes",
        carUseType: "pool"
    })

    const [vehicleType, setVehicleType] = useState([]);
    const [categories, setCategories] = useState([]);
    const [color, setColor] = useState([]);
    const [brand, setBrand] = useState([]);
    const [model, setModel] = useState([]);
    const [vehicleCity, setVehicleCity] = useState([]);
    const [vehicleClass, setVehicleClass] = useState([]);
    const [vehicleInfo, setVehicleInfo] = useState(initialState());

    useImperativeHandle(ref, () => ({
        setInitialState() {
            setVehicleInfo(initialState());
        }
    }));

    useEffect(() => {
        if (props.vehicleInfoEditData) {
            setVehicleInfo(props.vehicleInfoEditData);
        }
    }, [props.vehicleInfoEditData]);

    useEffect(() => {
        const getVehicleType = () => {
            fetch(process.env.REACT_APP_API_URL + "vehicleType", getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setVehicleType(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }

        const getAllCategories = () => {
            fetch(process.env.REACT_APP_API_URL + "categories", getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setCategories(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }

        const getAllVehicleClass = () => {
            fetch(process.env.REACT_APP_API_URL + "getVehicleClass", getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setVehicleClass(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }

        const getAllVehicleCity = () => {
            fetch(process.env.REACT_APP_API_URL + "getVehicleCity", getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setVehicleCity(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }


        setVehicleInfo(initialState());
        getVehicleType();
        getAllCategories();
        getAllVehicleClass();
        getAllVehicleCity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getAllColor = () => {
            fetch(process.env.REACT_APP_API_URL + "colors/" + vehicleInfo.idCategories, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setColor(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }

        const getAllBrand = () => {
            fetch(process.env.REACT_APP_API_URL + "brands/" + vehicleInfo.idCategories, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setBrand(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }

        if (vehicleInfo.idCategories && vehicleInfo.idCategories !== "") {
            getAllColor();
            getAllBrand();
        }

    }, [vehicleInfo.idCategories]);

    useEffect(() => {
        const getAllModel = () => {
            fetch(process.env.REACT_APP_API_URL + "models/" + vehicleInfo.idProducts, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setModel(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        if (vehicleInfo.idProducts && vehicleInfo.idProducts !== "") {
            getAllModel();
        }
    }, [vehicleInfo.idProducts]);

    useEffect(() => {
        props.onVehicleInfoChange(vehicleInfo);
    }, [props, vehicleInfo]);

    useEffect(() => {
        setVehicleInfo({ ...vehicleInfo, vehicleNumber: vehicleInfo.vehicleCity + "-" + vehicleInfo.vehicleClass + "-" + vehicleInfo.vehicleRegistrationNumber });
    }, [vehicleInfo.vehicleClass, vehicleInfo.vehicleCity, vehicleInfo.vehicleRegistrationNumber])

    const getAssetCode = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var projectId = 47;
            var url = process.env.REACT_APP_API_URL + "capitalization/searchCapitalizationItem/" + projectId + "?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.code;
                            element.value = element.capDetailsId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    const searchVendors = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "getAllVendors?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.vendorName;
                            element.value = element.idVendors;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    const handleInputUsageTypeChange = evt => {
        setVehicleInfo({ ...vehicleInfo, [evt.target.name]: evt.target.value });
    }

    const handleInputVehicleInfoChange = evt => {
        setVehicleInfo({ ...vehicleInfo, [evt.target.name]: evt.target.value });
    }

    const handleInputVehicleOwnerChange = evt => {
        setVehicleInfo({ ...vehicleInfo, [evt.target.name]: evt.target.value, capDetails: [], vendor: [] });
    }

    const handleInputVehicleTypeChange = vehicleType => {
        if (vehicleType.idVehicleType === 1 || vehicleType.idVehicleType === 2 || vehicleType.idVehicleType === 3 || vehicleType.idVehicleType === 11 || vehicleType.idVehicleType === 12) {
            var idCategories = 3442;
        }
        else if (vehicleType.idVehicleType === 4 || vehicleType.idVehicleType === 5) {
            idCategories = 3563;
        }
        else if (vehicleType.idVehicleType === 6 || vehicleType.idVehicleType === 7) {
            idCategories = 3606;
        }
        else if (vehicleType.idVehicleType === 8 || vehicleType.idVehicleType === 9 || vehicleType.idVehicleType === 10) {
            idCategories = 3605;
        }
        else if (vehicleType.idVehicleType === 13) {
            idCategories = 6058;
        }
        else if (vehicleType.idVehicleType === 14) {
            idCategories = 1908;
        }
        else if (vehicleType.idVehicleType === 15) {
            idCategories = 8302;
        }
        else if (vehicleType.idVehicleType === 16) {
            idCategories = 4591;
        }
        else if (vehicleType.idVehicleType === 17) {
            idCategories = 13171;
        }
        else if (vehicleType.idVehicleType === 19) {
            idCategories = 14784;
        }
        else {
            idCategories = '';
        }
        var categoryIndex = categories.findIndex(item =>
            item.idCategories === idCategories
        )
        if (categoryIndex !== -1) {
            setVehicleInfo({
                ...vehicleInfo, vehicleType: vehicleType, idCategories: idCategories, categories: {
                    idCategories: categories[categoryIndex].idCategories,
                    categoryName: categories[categoryIndex].categoryName,
                    label: categories[categoryIndex].categoryName,
                    value: categories[categoryIndex].idCategories
                }
            });
        }
        else {
            setVehicleInfo({ ...vehicleInfo, vehicleType: vehicleType, idCategories: idCategories, categories: [] });
        }
    }
    const handleAssetChange = assetDetails => {
        setVehicleInfo({ ...vehicleInfo, idCategories: assetDetails.idCategories, idColor: assetDetails.idColor, idProducts: assetDetails.idProducts, idModels: assetDetails.idModels, capDetails: assetDetails, color: { idColor: assetDetails.idColor, colorName: assetDetails.colorName, label: assetDetails.colorLabel, value: assetDetails.colorValue }, brand: { idProducts: assetDetails.idProducts, productsName: assetDetails.productsName, label: assetDetails.brandLabel, value: assetDetails.brandValue }, model: { idModels: assetDetails.idModels, modelName: assetDetails.modelName, label: assetDetails.modelLabel, value: assetDetails.modelValue } });
    }

    const handleVendorChange = vendor => {
        setVehicleInfo({ ...vehicleInfo, vendor: vendor });
    }

    const handleColorChange = color => {
        setVehicleInfo({ ...vehicleInfo, color: color, idColor: color.idColor });
    }

    const handleBrandChange = brand => {
        setVehicleInfo({ ...vehicleInfo, brand: brand, idProducts: brand.idProducts, manufacturer: brand.productsName, model: [] });
        props.onVehicleInfoChange(vehicleInfo);
    }

    const handleModelChange = model => {
        setVehicleInfo({ ...vehicleInfo, model: model, idModels: model.idModels });
        props.onVehicleInfoChange(vehicleInfo);
    }

    const handleCategoriesChange = categories => {
        setVehicleInfo({ ...vehicleInfo, categories: categories, color: [], model: [], brand: [] });
        props.onVehicleInfoChange(vehicleInfo);
    }

    const vehicleOwnerType = [
        { label: 'In House', value: 'In-House' },
        { label: 'Third Party', value: 'Third-Party' }
    ]

    const rentalTypeList = [
        { label: 'Rental Car (Full)', value: 'rental-car-full' },
        { label: 'Rental Car (Body)', value: 'rental-car-body' },
        { label: 'Not Available', value: 'not-available' }
    ]

    const carUseTypeList = [
        { label: 'Pool', value: 'pool' },
        { label: 'Individual', value: 'individual' },
        { label: 'Sharing', value: 'sharing' }
    ]

    const vehicleStatus = [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' }
    ]

    const vehicleUsageType = [

        { label: 'Select Usage Type', value: '' },
        { label: 'BOQ', value: 'BOQ' },
        { label: 'Overhead', value: 'Overhead' },
    ]

    const functionalStatus = [
        { label: 'Select functional status', value: '' },
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
    ]

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group row">
                        <label htmlFor="vehicleOwner" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Vehicle Owner<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <select className="form-select form-select-sm" id="vehicleOwner" name="vehicleOwner" value={vehicleInfo.vehicleOwner} onChange={handleInputVehicleOwnerChange} >
                                {vehicleOwnerType.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="usageType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Payable / Non Payable Item<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <select className="form-select form-select-sm" id="usageType" name="usageType" value={vehicleInfo.usageType} onChange={handleInputUsageTypeChange} >
                                {vehicleUsageType.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    {vehicleInfo.vehicleOwner === "In-House" &&
                        <div className="form-group row">
                            <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-4 col-form-label" >Fixed Asset Code</label>
                            <div className="col-lg-6">
                                <AsyncSelect
                                    value={vehicleInfo.capDetails}
                                    defaultOptions
                                    loadOptions={getAssetCode}
                                    placeholder="Select Asset"
                                    onChange={handleAssetChange}
                                    styles={customStylesSelect}
                                />
                            </div>
                        </div>
                    }
                    {vehicleInfo.vehicleOwner === "Third-Party" &&
                        <>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-4 col-form-label">Vendor<span className="required text-danger"></span></label>
                                <div className="col-lg-6">
                                    <AsyncSelect
                                        value={vehicleInfo.vendor}
                                        defaultOptions
                                        loadOptions={searchVendors}
                                        onChange={handleVendorChange}
                                        getOptionLabel={(option) => (
                                            <>
                                                <KTSVG
                                                    path='/media/svg/icons/Home/Home.svg'
                                                    className='svg-icon-2 svg-icon-primary'
                                                />
                                                <span> {option.label} </span><br></br>
                                                <KTSVG
                                                    path='media/svg/icons/Map/Marker1.svg'
                                                    className='svg-icon-2 svg-icon-warning'
                                                />
                                                <span> {option.vendorAddress} </span><br></br>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div className="form-group row mt-2">
                        <label htmlFor="vehicleType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Vehicle Type<span className="required text-danger"></span></label>

                        <div className="col-lg-6">
                            <Select
                                value={vehicleInfo.vehicleType}
                                onChange={handleInputVehicleTypeChange}
                                options={vehicleType}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Category<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <Select
                                value={vehicleInfo.categories}
                                onChange={handleCategoriesChange}
                                options={categories}
                                styles={customStylesSelect}
                                isDisabled
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Brand<span className="required text-danger"></span></label>

                        <div className="col-lg-6">
                            <Select
                                value={vehicleInfo.brand}
                                onChange={handleBrandChange}
                                options={brand}
                                styles={customStylesSelect}
                            />
                        </div>
                    </div>


                    {/* <div className="form-group row">
                        <label htmlFor="manufacturer" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Manufacturer<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="text" name="manufacturer" id="manufacturer" value={vehicleInfo.manufacturer} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" readOnly />
                        </div>
                    </div> */}

                    <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Model<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <Select
                                value={vehicleInfo.model}
                                onChange={handleModelChange}
                                options={model}
                                styles={customStylesSelect}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="yearOfManufacture" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Manufacturing Year<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="number" name="yearOfManufacture" id="yearOfManufacture" value={vehicleInfo.yearOfManufacture} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Color <span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <Select
                                value={vehicleInfo.color}
                                onChange={handleColorChange}
                                options={color}
                                styles={customStylesSelect}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentalType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Rental Type<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <select className="form-select form-select-sm" id="rentalType" name="rentalType" value={vehicleInfo.rentalType} onChange={handleInputVehicleInfoChange}>
                                {rentalTypeList.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="carUseType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Car Use Type<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <select className="form-select form-select-sm" id="carUseType" name="carUseType" value={vehicleInfo.carUseType} onChange={handleInputVehicleInfoChange}>
                                {carUseTypeList.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    {vehicleInfo.rentalType === 'rental-car-full' &&
                        <>
                            <div className="form-group row">
                                <label htmlFor="fixedAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Fixed Amount</label>
                                <div className="col-lg-6">
                                    <input type="number" name="fixedAmount" id="fixedAmount" value={vehicleInfo.fixedAmount} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="lastPolicyReviewOn" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Last Policy Review On</label>
                                <div className="col-lg-6">
                                    <input type="date" name="lastPolicyReviewOn" id="lastPolicyReviewOn" value={vehicleInfo.lastPolicyReviewOn} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="milage" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Milage</label>
                                <div className="col-lg-6">
                                    <input type="number" name="milage" id="milage" value={vehicleInfo.milage} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                        </>
                    }
                    {vehicleInfo.rentalType === 'rental-car-body' &&
                        <>
                            <div className="form-group row">
                                <label htmlFor="fixedAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Fixed Amount</label>
                                <div className="col-lg-6">
                                    <input type="number" name="fixedAmount" id="fixedAmount" value={vehicleInfo.fixedAmount} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="lastPolicyReviewOn" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Last Policy Review On</label>
                                <div className="col-lg-6">
                                    <input type="date" name="lastPolicyReviewOn" id="lastPolicyReviewOn" value={vehicleInfo.lastPolicyReviewOn} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="milage" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Milage</label>
                                <div className="col-lg-6">
                                    <input type="number" name="milage" id="milage" value={vehicleInfo.milage} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="monthlyExpense" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Monthly Expense</label>
                                <div className="col-lg-6">
                                    <input type="number" name="monthlyExpense" id="monthlyExpense" value={vehicleInfo.monthlyExpense} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="lastReviewDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Last Review Date</label>
                                <div className="col-lg-6">
                                    <input type="date" name="lastReviewDate" id="lastReviewDate" value={vehicleInfo.lastReviewDate} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                                </div>
                            </div>
                        </>
                    }
                    <div className="form-group row">
                        <label htmlFor="purchaseDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Vehicle Purchase Date</label>
                        <div className="col-lg-6">
                            <input type="date" name="purchaseDate" id="purchaseDate" value={vehicleInfo.purchaseDate || ''} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group row">
                        <label htmlFor="vehicleNumber" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Vehicle Number<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <div className="input-group">
                                {/* <input type="text" name="vehicleNumber" id="vehicleNumber" value={vehicleInfo.vehicleNumber} onChange={handleInputVehicleInfoChange} readOnly={props.idVmsVehicleInfo} className="form-control form-control-sm" /> */}
                                <select className="form-select form-select-sm" id="vehicleCity" name="vehicleCity" value={vehicleInfo.vehicleCity} onChange={handleInputVehicleInfoChange}>
                                    <option value=""></option>
                                    {vehicleCity.map(function (item, id) {
                                        return <option key={id} value={item.vehicleCity}>{item.vehicleCity}</option>
                                    })}
                                </select>
                                <select className="form-select form-select-sm" id="vehicleClass" name="vehicleClass" value={vehicleInfo.vehicleClass} onChange={handleInputVehicleInfoChange}>
                                    <option value=""></option>
                                    {vehicleClass.map(function (item, id) {
                                        return <option key={id} value={item.vehicleClass}>{item.vehicleClass}</option>
                                    })}
                                </select>
                                <InputMask mask="99-9999" name="vehicleRegistrationNumber" value={vehicleInfo.vehicleRegistrationNumber} onChange={handleInputVehicleInfoChange} placeholder="99-9999">
                                    {(inputProps) => <input {...inputProps} type="text" className="form-control form-control-sm" />}
                                </InputMask>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="chasisNumber" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Chassis Number<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="text" name="chasisNumber" id="chasisNumber" value={vehicleInfo.chasisNumber} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="vtsOrGps" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >VTS/GPS<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <select className="form-select form-select-sm" id="vtsOrGps" name="vtsOrGps" value={vehicleInfo.vtsOrGps} onChange={handleInputVehicleInfoChange} >
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </div>
                    </div>
                    {vehicleInfo.vtsOrGps === "yes" &&
                        <div className="form-group row">
                            <label htmlFor="vtsOrGpsFunctioning" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >VTS/GPS Functioning<span className="required text-danger"></span></label>
                            <div className="col-lg-6">
                                <select className="form-select form-select-sm" id="vtsOrGpsFunctioning" name="vtsOrGpsFunctioning" value={vehicleInfo.vtsOrGpsFunctioning} onChange={handleInputVehicleInfoChange} >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>
                    }
                    <div className="form-group row">
                        <label htmlFor="vtsNo" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >VTS No</label>
                        <div className="col-lg-6">
                            <input type="text" name="vtsNo" id="vtsNo" value={vehicleInfo.vtsNo} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="eTrackingNumber" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >E-tracking Number</label>
                        <div className="col-lg-6">
                            <input type="text" name="eTrackingNumber" id="eTrackingNumber" value={vehicleInfo.eTrackingNumber} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="overHang" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Overhang</label>
                        <div className="col-lg-6">
                            <input type="text" name="overHang" id="overHang" value={vehicleInfo.overHang} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="registrationDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Registration Date<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="date" name="registrationDate" id="registrationDate" value={vehicleInfo.registrationDate} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="transactionNumber" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Transaction Number</label>
                        <div className="col-lg-6">
                            <input type="text" name="transactionNumber" id="transactionNumber" value={vehicleInfo.transactionNumber} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="nameOfRegistration" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Name of Registration<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="text" name="nameOfRegistration" id="nameOfRegistration" value={vehicleInfo.nameOfRegistration} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="currentMeterReading" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Current Meter Reading<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="number" name="currentMeterReading" id="currentMeterReading" value={vehicleInfo.currentMeterReading} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="taxAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Tax Amount<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="number" name="taxAmount" id="taxAmount" value={vehicleInfo.taxAmount} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="taxTokenExpirationDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Tax Token Expiration Date<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <input type="date" name="taxTokenExpirationDate" id="taxTokenExpirationDate" value={vehicleInfo.taxTokenExpirationDate} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="fitnessExpirationDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Fitness Expiration Date {vehicleInfo.vehicleType.idVehicleType !== 14 && <span className="required text-danger"></span>}</label>
                        <div className="col-lg-6">
                            <input type="date" name="fitnessExpirationDate" id="fitnessExpirationDate" value={vehicleInfo.fitnessExpirationDate} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="routePermitExpirationDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Route Permit Expiration Date</label>
                        <div className="col-lg-6">
                            <input type="date" name="routePermitExpirationDate" id="routePermitExpirationDate" value={vehicleInfo.routePermitExpirationDate} onChange={handleInputVehicleInfoChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="vehicleStatus" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Vehicle Status<span className="required text-danger"></span></label>
                        <div className="col-lg-6">
                            <select className="form-select form-select-sm" id="vehicleStatus" name="vehicleStatus" value={vehicleInfo.vehicleStatus} onChange={handleInputVehicleInfoChange} >
                                {vehicleStatus.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    {vehicleInfo.vehicleStatus === "Active" &&
                        <>
                            <div className="form-group row">
                                <label htmlFor="functional" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Functional Status<span className="required text-danger"></span></label>
                                <div className="col-lg-6">
                                    <select className="form-select form-select-sm" id="functional" name="functional" value={vehicleInfo.functional} onChange={handleInputVehicleInfoChange} >
                                        {functionalStatus.map(function (item, id) {
                                            return <option key={id} value={item.value}>{item.label}</option>
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        </>

                    }
                </div>
            </div>
            {/* <pre>
                {JSON.stringify(vehicleInfo, null, 2)}
            </pre> */}
        </>
    )
})

export default React.memo(VehicleInfo);