import React, { useContext, useEffect, useState } from "react";
import { GetPagePermission } from "../../components/UserPagePermission";
import userPermissionContext from '../../../context/userPermissionContext';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { KTSVG } from '../../../_metronic/helpers';
import BackendTable from 'react-backend-table';
import { Link } from "react-router-dom";

function VehicleInfoReport() {
    const { jwt } = useContext(userPermissionContext);
    const hasPermission = GetPagePermission("/report-vehicleInfoReport");
    const [reload, setReload] = useState(false);

    const [formData, setFormData] = useState({
        vehicleStatus: "",
        vehicleType: "",
    });

    let columns = [
        { title: 'Sid', field: 'idVmsVehicleInfo', sortable: true, searchable: true },
        {
            title: 'Number', field: 'vehicleNumber', sortable: true, searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
                        {rowValue.vehicleNumber}
                    </Link>
                )

            })
        },
        { title: 'Chassis No', field: 'chassisNo', sortable: true, searchable: true },
        { title: 'Payable ?', field: 'usageType', sortable: true, searchable: true },
        { title: 'Owner', field: 'vehicleOwner', sortable: true, searchable: true },
        { title: 'Type', field: 'vehicleType', sortable: true, searchable: true },
        { title: 'Use Type', field: 'carUseType', sortable: true, searchable: true },
        { title: 'Asset Code', field: 'fixedAssetCode', searchable: true, sortable: true },
        { title: 'Vendor', field: 'vendorName', searchable: true, sortable: true },
        { title: 'Category', field: 'categoryName', searchable: true, sortable: true },
        { title: 'Color', field: 'colorName', searchable: true, sortable: true },
        { title: 'Brand', field: 'productName', searchable: true, sortable: true },
        { title: 'Model', field: 'modelName', searchable: true, sortable: true },
        { title: 'Manufacturing Year', field: 'manufacturingYear', searchable: true, sortable: true },
        { title: 'Fixed Amount', field: 'fixedAmount', searchable: true, sortable: true },
        { title: 'Last Policy Review On', field: 'lastPolicyReviewOn', searchable: true, sortable: true },
        { title: 'Milage', field: 'milage', searchable: true, sortable: true },
        { title: 'Vts No', field: 'vtsNo', searchable: true, sortable: true },
        { title: 'E-tracking Number', field: 'eTrackingNumber', searchable: true, sortable: true },
        { title: 'Overhang', field: 'overHang', searchable: true, sortable: true },
        { title: 'Registration Date', field: 'registrationDate', searchable: true, sortable: true },
        { title: 'Transaction Number', field: 'transactionNumber', searchable: true, sortable: true },
        { title: 'Name of Registration', field: 'nameOfRegistration', searchable: true, sortable: true },
        { title: 'Current Meter Reading', field: 'currentMeterReading', searchable: true, sortable: true },
        { title: 'Tax Amount', field: 'taxAmount', searchable: true, sortable: true },
        { title: 'Tax token expiration date', field: 'taxTokenExpirationDate', searchable: true, sortable: true },
        { title: 'Fitness expiration date', field: 'fitnessExpirationDate', searchable: true, sortable: true },
        { title: 'Route permit expiration date', field: 'routePermitExpirationDate', searchable: true, sortable: true },
        { title: 'No Of Tyres', field: 'noOfTyres', searchable: true, sortable: true },
        { title: 'Seat Capacity', field: 'seatCapacity', searchable: true, sortable: true },
        { title: 'Spare Tyres', field: 'spareTyres', searchable: true, sortable: true },
        { title: 'Fuel Capacity', field: 'fuelCapacity', searchable: true, sortable: true },
        { title: 'Cylinder Capacity', field: 'cylinderCapacity', searchable: true, sortable: true },
        { title: 'Engine Number', field: 'engineNumber', searchable: true, sortable: true },
        { title: 'Laden Weight (Kg)', field: 'ladenWeight', searchable: true, sortable: true },
        { title: 'Engine (CC) Displacement', field: 'engineCcDisplacement', searchable: true, sortable: true },
        { title: 'Insurance Type', field: 'insuranceType', searchable: true, sortable: true },
        { title: 'Insurance Company', field: 'insuranceCompany', searchable: true, sortable: true },
        { title: 'Insurance Policy Number', field: 'insurancePolicyNumber', searchable: true, sortable: true },
        { title: 'Insurance Expiration Date', field: 'insuranceExpirationDate', searchable: true, sortable: true },
        { title: 'Yearly Premium', field: 'yearlyPremium', searchable: true, sortable: true },
        { title: 'Source', field: 'source', searchable: true, sortable: true },
        { title: 'Project', field: 'assignedProject', sortable: true, searchable: true },
        { title: 'Personnel', field: 'assignedUser', sortable: true, searchable: true },
        { title: 'Designation', field: 'assignedUserDesignation', sortable: true, searchable: true },
        { title: 'Consultant Or Advisor', field: 'consultantOrAdvisorName', searchable: true, sortable: true },
        { title: 'Assigned Driver', field: 'assignedDriver', sortable: true, searchable: true },
        { title: 'Driver Mobile No', field: 'driverMobileNumber', sortable: true, searchable: true },
        {
            title: 'Status',
            field: 'vehicleStatus',
            sortable: false,
            searchable: false,
            tdClass: "text-center",
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (rowValue.vehicleStatus === "Active" ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>)
            })
        },
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'vehicleInfoReport',
        authorization: 'Bearer ' + jwt.token,
        perPage: [50, 100, 500, 1000, 'All'],
        orderBy: 'idVmsVehicleInfo',
        orderType: 'desc',
        columnSearch: true,
        extraData: formData
    }
    useEffect(() => {
        if (reload) {
            options.reloadMyTable();
            setReload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const typeList = [
        { value: '', label: 'All' },
        { value: 'light', label: 'Light Vehicle' },
        { value: 'motor-cycle', label: 'Motor Cycle' },
    ];

    const statusList = [
        { value: '', label: 'All' },
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ];

    const handleInputOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setReload(true);
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header d-flex flex-direction-row justify-content-between '>
                        <div className='card-title w-25'>
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Vehicle Info Report
                            </span>
                        </div>

                        <div className='row mt-5'>
                            <label htmlFor="vehicleType" className="col-form-label col-lg-2" style={{ textAlign: "center" }} >Type</label>
                            <div className="col-lg-4">
                                <select className="form-select form-select-sm" id="vehicleType" name="vehicleType" value={formData.vehicleType} onChange={handleInputOnChange}>
                                    {typeList.map(function (item, id) {
                                        return <option key={id} value={item.value}>{item.label}</option>
                                    })
                                    }
                                </select>
                            </div>
                            <label htmlFor="vehicleStatus" className="col-form-label col-lg-2" style={{ textAlign: "center" }} >Status</label>
                            <div className="col-lg-4">
                                <select className="form-select form-select-sm" id="vehicleStatus" name="vehicleStatus" value={formData.vehicleStatus} onChange={handleInputOnChange}>
                                    {statusList.map(function (item, id) {
                                        return <option key={id} value={item.value}>{item.label}</option>
                                    })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className='ml-12'>
                        <div className='' style={{ overflowX: "hidden" }}>
                            <BackendTable columns={columns} options={options} />
                        </div>
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    );
}

export default VehicleInfoReport;