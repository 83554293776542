import React, { useState, useEffect, useImperativeHandle, useCallback } from "react";
import { getRequestOptions } from "../components/GetToken";
import AsyncSelect from "react-select/async";
import Radio from "@mui/material/Radio";

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: "calc(1.35em + 1.1rem + 2px)",
        minHeight: "35px",
    }),
};

const VehicleAssign = React.forwardRef(({ vehicleAssignEditData, vehicleInfo, onVehicleAssignChange }, ref) => {
    const initialState = {
        project: "",
        driver: "",
        payrollUser: "yes",
        consultantOrAdvisorName: "",
        consultantOrAdvisorDesignation: "",
        assignedUser: "",
        selfDriven: "no"
    };

    const [vehicleAssign, setVehicleAssign] = useState(initialState);
    const [fieldStatus, setFieldStatus] = useState(false);

    useImperativeHandle(ref, () => ({
        setInitialState: () => setVehicleAssign(initialState),
    }));

    useEffect(() => {
        if (vehicleAssignEditData) {
            setVehicleAssign(vehicleAssignEditData);
            setFieldStatus(true);
        } else {
            setFieldStatus(false);
        }
    }, [vehicleAssignEditData]);

    useEffect(() => {
        onVehicleAssignChange(vehicleAssign);
    }, [vehicleAssign, onVehicleAssignChange]);

    const fetchOptions = useCallback(async (url, inputValue, callback, labelField, valueField) => {
        if (!inputValue) return callback([]);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}?q=${inputValue}`, getRequestOptions());
            const data = await response.json();
            callback(
                data.data.map((item) => ({
                    label: item[labelField],
                    value: item[valueField],
                }))
            );
        } catch (error) {
            console.error("Error fetching data:", error);
            callback([]);
        }
    }, []);

    const getAllProject = (inputValue, callback) =>
        fetchOptions("getBusinessUnits", inputValue, callback, "projectName", "projectId");

    const getAllDriver = (inputValue, callback) =>
        fetchOptions("getAllDriver", inputValue, callback, "fullName", "idVmsDrivers");

    const getUserForAssign = (inputValue, callback) =>
        fetchOptions("users/getUserForAssign", inputValue, callback, "fullName", "employeeId");

    const handleChange = (name, value) => setVehicleAssign((prev) => ({ ...prev, [name]: value }));

    return (
        <>
            <div className="form-group row">
                <label className="col-lg-3 col-form-label text-end">Project Name</label>
                <div className="col-lg-5">
                    <AsyncSelect
                        isDisabled={fieldStatus}
                        value={vehicleAssign.project}
                        loadOptions={getAllProject}
                        placeholder="Select Project"
                        onChange={(value) => handleChange("project", value)}
                        styles={customStylesSelect}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-lg-3 col-form-label text-end">Payroll User</label>
                <div className="col-lg-5">
                    <select
                        className="form-select form-select-sm"
                        value={vehicleAssign.payrollUser}
                        onChange={(e) => handleChange("payrollUser", e.target.value)}
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
            </div>

            {vehicleAssign.payrollUser === "yes" ? (
                <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-end">
                        Assign To User {vehicleInfo?.vehicleType?.idVehicleType === 14 && <span className="text-danger">*</span>}
                    </label>
                    <div className="col-lg-5">
                        <AsyncSelect
                            isDisabled={fieldStatus}
                            value={vehicleAssign.assignedUser}
                            loadOptions={getUserForAssign}
                            placeholder="Select User"
                            onChange={(value) => handleChange("assignedUser", value)}
                            styles={customStylesSelect}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-end">Consultant / Advisor's Name</label>
                        <div className="col-lg-5">
                            <input
                                type="text"
                                name="consultantOrAdvisorName"
                                value={vehicleAssign.consultantOrAdvisorName}
                                onChange={(e) => handleChange("consultantOrAdvisorName", e.target.value)}
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-end">Designation</label>
                        <div className="col-lg-5">
                            <input
                                type="text"
                                name="consultantOrAdvisorDesignation"
                                value={vehicleAssign.consultantOrAdvisorDesignation}
                                onChange={(e) => handleChange("consultantOrAdvisorDesignation", e.target.value)}
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="form-group row">
                <label className="col-lg-3 col-form-label text-end">Self Driven <span className="text-danger">*</span></label>
                <div className="col-lg-5">
                    {["no", "yes"].map((option) => (
                        <label key={option} className="me-3">
                            <Radio
                                checked={vehicleAssign.selfDriven === option}
                                onClick={() => setVehicleAssign((prev) => ({ ...prev, selfDriven: option }))}
                                value={option}
                                name="selfDriven"
                            />
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                        </label>
                    ))}
                </div>
            </div>
            {vehicleAssign.selfDriven === "no" && vehicleInfo?.vehicleType?.idVehicleType !== 14 && (
                <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-end">
                        Driver Name
                    </label>
                    <div className="col-lg-5">
                        <AsyncSelect
                            isDisabled={fieldStatus}
                            value={vehicleAssign.driver}
                            loadOptions={getAllDriver}
                            placeholder="Select Driver"
                            onChange={(value) => handleChange("driver", value)}
                            styles={customStylesSelect}
                        />
                    </div>
                </div>
            )}
        </>
    );
});

export default React.memo(VehicleAssign);
