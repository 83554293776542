import React, { useEffect, useState, useContext } from "react";
import { GetPagePermission } from "../../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../../context/userPermissionContext';
import { KTSVG } from '../../../_metronic/helpers';
import Select from 'react-select';
import { getRequestOptions } from "../../components/GetToken";
import BackendTable from 'react-backend-table';

function VehicleCostReport() {
    const { jwt } = useContext(userPermissionContext);
    const hasPermission = GetPagePermission('/report-vehicleCostReport');
    const [projectList, setProjectList] = useState([]);
    const [formData, setFormData] = useState({
        project: "",
        expenseStartDate: "",
        expenseEndDate: ""
    });

    useEffect(() => {
        getProjectList();
    }, []);

    const getProjectList = () => {
        fetch(process.env.REACT_APP_API_URL + "getProjectList", getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setProjectList(resp.data.map((item) => ({ label: item.label, value: item.projectId })));
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const handleProjectChange = (selectedOption) => {
        setFormData({ ...formData, project: selectedOption });
    }

    let columns = [
        { title: 'Vehicle Id', field: 'idVmsVehicleInfo', sortable: true, searchable: true },
        { title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true },
        { title: 'Vehicle Type', field: 'vehicleType', sortable: true, searchable: true },
        { title: 'Brand', field: 'productName', sortable: true, searchable: true },
        { title: 'Model', field: 'modelName', sortable: true, searchable: true },
        { title: 'Year of Manufacture', field: 'yearOfManufacture', sortable: true, searchable: true },
        { title: 'Vehicle Color', field: 'colorName', sortable: true, searchable: true },
        { title: 'CC of Vehicle', field: 'engineCCDisplacement', sortable: true, searchable: true },
        { title: 'Car Use Type', field: 'carUseType', sortable: true, searchable: true },
        { title: 'User', field: 'userName', sortable: true, searchable: true },
        { title: 'Designation', field: 'designation', sortable: true, searchable: true },
        { title: 'Name of Registration', field: 'nameOfRegistration', sortable: true, searchable: true },
        { title: 'Vehicle Owner', field: 'vehicleOwner', sortable: true, searchable: true },
        { title: 'Business Unit', field: 'projectName', sortable: true, searchable: true },
        {
            title: 'Run By', field: 'runBy', sortable: true, searchable: true, hasComponent: true,
            componentValue: (rowValue) => {
                return rowValue.runBy === "octane" ? (
                    <span>Octane</span>
                ) : rowValue.runBY === "diesel" ? (
                    <span>Diesel</span>
                ) : rowValue.runBy === "petrol" ? (
                    <span>Petrol</span>

                ) : rowValue.runBy === "cng" ? (
                    <span>CNG</span>
                ) : rowValue.runBy === "lpg" ? (
                    <span>LPG</span>
                ) : rowValue.runBy === "cng_octane" ? (
                    <span>CNG-Octane</span>
                ) : rowValue.runBy === "lpg_octane" ? (
                    <span>LPG-Octane</span>
                ) : <span>{rowValue.runBy}</span>
            },
        },
        { title: 'Fuel Tank Capacity', field: 'fuelCapacity', sortable: true, searchable: true },
        { title: 'Quantity of Fuel', field: 'totalFuelConsumption', sortable: true, searchable: true },
        { title: 'Previous Meter Reading KM', field: 'previousMeterReading', sortable: true, searchable: true },
        { title: 'Current Meter Reading KM', field: 'currentMeterReading', sortable: true, searchable: true },
        { title: 'Distance/ Actual KM', field: 'totalDistance', sortable: true, searchable: true },
        { title: 'Per Liter Running (KM)', field: 'perLiterRunning', sortable: true, searchable: true },
        { title: 'Unit Price of Fuel (TK)', field: 'fuelUnitPrice', sortable: true, searchable: true },
        { title: 'Fuel Cost (TK)', field: 'totalFuelCost', sortable: true, searchable: true },
        { title: 'Per KM Fuel Cost (TK)', field: 'perKmFuelCost', sortable: true, searchable: true },
        { title: 'Unit Price of Octane (TK)', field: 'octaneUnitPrice', sortable: true, searchable: true },
        { title: 'Octane Qty', field: 'totalOctaneConsumption', sortable: true, searchable: true },
        { title: 'Octane Cost (TK)', field: 'totalOctaneCost', sortable: true, searchable: true },
        { title: 'Total Fuel Cost', field: 'grandTotalFuelCost', sortable: true, searchable: true },
        { title: 'Monthly Rent', field: 'totalRentalCost', sortable: true, searchable: true },
        { title: 'Repair/ Maintenance Cost', field: 'totalMaintenanceCost', sortable: true, searchable: true },
        { title: 'Documentation Cost', field: 'totalDocumentationCost', sortable: true, searchable: true },
        { title: 'Miscellaneous Cost', field: 'totalMiscellaneousCost', sortable: true, searchable: true },
        { title: 'Monthly Total Cost', field: 'totalCost', sortable: true, searchable: true },
        { title: 'Payable/ Non Payable Item', field: 'usageType', sortable: true, searchable: true },
        {
            title: 'VTS/GPS', field: 'vtsOrGps', sortable: true, searchable: true, hasComponent: true, componentValue: (rowValue) => {
                return rowValue.vtsOrGps?.charAt(0).toUpperCase() + rowValue.vtsOrGps?.slice(1)
            }
        },
        {
            title: 'Functioning Of VTS/GPS', field: 'vtsOrGpsFunctioning', sortable: true, searchable: true, hasComponent: true, componentValue: (rowValue) => {
                return rowValue.vtsOrGpsFunctioning !== null ? rowValue.vtsOrGpsFunctioning?.charAt(0).toUpperCase() + rowValue.vtsOrGpsFunctioning?.slice(1) : ""
            }
        },
        { title: 'Vehicle Parking at Night', field: 'parkingLocation', sortable: true, searchable: true },
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'vehicleCostReport',
        authorization: 'Bearer ' + jwt.token,
        perPage: [50, 100, 500, 1000, 'All'],
        orderBy: 'idVmsVehicleInfo',
        orderType: 'desc',
        columnSearch: true,
        extraData: formData
    }

    useEffect(() => {
        if (formData.project !== "" || formData.expenseStartDate !== "" || formData.expenseEndDate !== "") {
            options.reloadMyTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <>
            {hasPermission === 1 &&
                <div className="card card-custom">
                    <div className='card-header d-flex flex-direction-row justify-content-between py-4'>
                        <div className='card-title w-50'>
                            <KTSVG
                                path='/media/svg/icons/Shopping/Money.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                VEHICLE COST REPORT
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <div className="card-body">
                            <div className="form-group row mt-5">
                                <label htmlFor="project" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Project<span className="required text-danger"></span></label>
                                <div className="col-lg-4">
                                    <Select
                                        value={formData.project}
                                        options={projectList}
                                        onChange={handleProjectChange}
                                        isClearable
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <div className="form-group row mt-5">
                                <label htmlFor="expenseStartDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Start Date<span className="required text-danger"></span></label>
                                <div className="col-lg-4">
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="expenseStartDate"
                                        name="expenseStartDate"
                                        value={formData.expenseStartDate}
                                        onChange={(e) => setFormData({ ...formData, expenseStartDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mt-5">
                                <label htmlFor="expenseEndDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense End Date<span className="required text-danger"></span></label>
                                <div className="col-lg-4">
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="expenseEndDate"
                                        name="expenseEndDate"
                                        value={formData.expenseEndDate}
                                        onChange={(e) => setFormData({ ...formData, expenseEndDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='' style={{ overflowX: "hidden" }}>
                                <BackendTable columns={columns} options={options} />
                            </div>
                        </div>

                    </div>
                </div>
            }
            {
                hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default VehicleCostReport;